import React from "react";
import Modal from "../../Modal";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { deleteReport } from "./redux/actions";
import { FormattedMessage, injectIntl } from "react-intl";

class DeleteItem extends React.Component {
  deleteReport = (report) => {
    this.props.deleteReport(report);
  };

  renderActions = () => {
    const { match } = this.props;
    const user = match.params.user;
    const id = match.params.report;
    const report = { user, id };
    return (
      <React.Fragment>
        <button
          className="ui red button"
          onClick={() => this.deleteReport(report)}
        >
          <FormattedMessage id="common.delete" />
        </button>
        <Link to={`/dashboard/report/${user}`} className="ui button">
          <FormattedMessage id="common.cancel" />
        </Link>
      </React.Fragment>
    );
  };

  render() {
    const { intl } = this.props;
    return (
      <Modal
        title={intl.formatMessage({ id: "deleteItem.title" })}
        content={intl.formatMessage({ id: "deleteItem.message" })}
        actions={this.renderActions()}
      />
    );
  }
}
export default connect(null, { deleteReport })(
  injectIntl(injectIntl(DeleteItem))
);
