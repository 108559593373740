import { takeLatest, put, all, fork } from "redux-saga/effects";
import { WATCH_GET_USERS, ADD_USER, DELETE_USER, EDIT_USER } from "./types";
import { setUsers } from "./actions";
import { db, usersFlowAuth, users } from "../../../../services/firebase";
import history from "../../../../history";

function* settingUsers({ searchTerm }) {
  try {
    const query = yield db.collection(users).where("hide", "==", false).get();
    yield put(setUsers(query, searchTerm));
  } catch (error) {
    yield alert(error);
  }
}

function* addingUser({ newUser }) {
  try {
    yield usersFlowAuth.createUserWithEmailAndPassword(newUser.email, "123456");
    yield db
      .collection(users)
      .doc(usersFlowAuth.currentUser.uid)
      .set({
        displayName: newUser.name,
        isAdmin: newUser.type === "admin" ? true : false,
        organizationId: newUser.organizationId,
        email: newUser.email,
        hide: false,
      });
    yield usersFlowAuth.currentUser.updateProfile({
      displayName: newUser.name,
    });
    yield usersFlowAuth.sendPasswordResetEmail(newUser.email);
    yield usersFlowAuth.signOut();
    history.push("/dashboard");
    yield put({ type: WATCH_GET_USERS, searchTerm: "" });
  } catch (error) {
    yield alert(error);
    history.push("/dashboard");
  }
}

function* edditingUser({ user }) {
  try {
    yield db
      .collection(users)
      .doc(user.id)
      .update({ displayName: user.displayName });
    history.push("/dashboard");
    yield put({ type: WATCH_GET_USERS, searchTerm: "" });
  } catch (error) {
    yield alert(error);
    yield history.push("/dashboard");
  }
}

function* delettingUser({ userId }) {
  yield db.collection(users).doc(userId).update({ hide: true });
  history.push("/dashboard");
  yield put({ type: WATCH_GET_USERS, searchTerm: "" });
}

function* watchUser() {
  yield takeLatest(WATCH_GET_USERS, settingUsers);
}

function* deleteUser() {
  yield takeLatest(DELETE_USER, delettingUser);
}

function* addUser() {
  yield takeLatest(ADD_USER, addingUser);
}

function* editUser() {
  yield takeLatest(EDIT_USER, edditingUser);
}

function* UsersSaga() {
  yield all([fork(watchUser), fork(addUser), fork(deleteUser), fork(editUser)]);
}

export default UsersSaga;
