import React from "react";
import SearchBar from "./SearchBar";
import UserList from "./Users/UserList";
import "../../assets/css/pages.css";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { getUsers } from "./Users/redux/actions";
import { FormattedMessage } from "react-intl";
import { ColorContext } from "../../Root";
import { getStyle } from "../../assets/styles/styles";

class Dashboard extends React.Component {
  static contextType = ColorContext;

  componentDidMount() {
    this.props.getUsers("");
  }

  onSearch = async (searchTerm) => {
    searchTerm ? this.props.getUsers(searchTerm) : this.props.getUsers("");
  };

  render() {
    const { isAdmin, uid } = this.props.user;
    if (isAdmin === false) {
      return <Redirect to={`/dashboard/report/${uid}`} />;
    } else {
      return (
        <div className="main-box">
          <div className="main-bar">
            <div className="left-content">
              <label className="ui basic large label ">
                <FormattedMessage id="dashboard.users" />
              </label>
              <Link
                to={{
                  pathname: "/dashboard/message",
                  state: { isModal: true },
                }}
                className={`ui large icon label `}
                style={getStyle(this.context).button}
              >
                <i className="envelope icon" />
                <FormattedMessage id="dashboard.sendMessage" />
              </Link>
            </div>

            <div className="right-content">
              <Link
                to={{
                  pathname: "/dashboard/add-user",
                  state: { isModal: true },
                }}
                className={`ui large icon label `}
                style={getStyle(this.context).button}
              >
                <i className="plus icon" />
                <FormattedMessage id="dashboard.addNew" />
              </Link>
              <SearchBar onSearch={this.onSearch} />
            </div>
          </div>
          <div className="main-content">
            <UserList />
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, { getUsers })(Dashboard);
