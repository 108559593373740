import React from "react";
import { ChromePicker } from "react-color";

class ColorPicker extends React.Component {
  state = {
    displayColorPicker: false,
    backgroundColor: {
      r: "241",
      g: "112",
      b: "19",
      a: "1",
    },
    letterColor: "white",
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  getContrast(hexcolor) {
    hexcolor = hexcolor.slice(1);
    let r = parseInt(hexcolor.substr(0, 2), 16);
    let g = parseInt(hexcolor.substr(2, 2), 16);
    let b = parseInt(hexcolor.substr(4, 2), 16);
    let yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "dimgrey" : "white";
  }

  handleChange = (color) => {
    const letterColor = this.getContrast(color.hex);
    this.setState({ backgroundColor: color.rgb, letterColor });
    this.props.onChange(this.state);
  };

  render() {
    const styles = {
      color: {
        width: "36px",
        height: "18px",
        borderRadius: "0.28em",
        background: `rgba(${this.state.backgroundColor.r}, ${this.state.backgroundColor.g}, ${this.state.backgroundColor.b}, ${this.state.backgroundColor.a})`,
      },
      swatch: {
        marginTop: "5px",
        padding: "0.67em 1em",
        background: "#fff",
        borderRadius: "0.28em",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        left: "-155px",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    };

    return (
      <div className="ui form">
        <div className="field">
          <label>Color</label>
          <div style={styles.swatch} onClick={this.handleClick}>
            <div style={styles.color} />
          </div>
        </div>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <ChromePicker
              color={this.state.backgroundColor}
              onChange={this.handleChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColorPicker;
