import React from "react";
import Modal from "../../Modal";
import { connect } from "react-redux";
import { addNew } from "./redux/actions";
import { Field, reduxForm } from "redux-form";
import { validate, renderError } from "../../utils/validations";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import ColorPicker from "./ColorPicker";

class NewOrganization extends React.Component {
  state = {
    backgroundColor: `rgba(241, 112, 19, 1)`,
    color: "white",
    isLoading: false,
  };

  renderInput = ({ meta, label, input, type }) => {
    return (
      <div className="required field">
        <label>{label}</label>
        <input {...input} type={type} />
        {renderError(meta)}
      </div>
    );
  };

  renderColor = (colors) => {
    const rgbaString = `rgba(${colors.backgroundColor.r}, ${colors.backgroundColor.g}, ${colors.backgroundColor.b}, ${colors.backgroundColor.a})`;
    this.setState({ backgroundColor: rgbaString, color: colors.letterColor });
  };

  onSubmit = (formValues) => {
    formValues = {
      ...formValues,
      color: this.state.backgroundColor,
      textColor: this.state.color,
    };
    this.props.addNew(formValues);
    this.setState({ isLoading: true });
  };

  renderContent() {
    const { intl, handleSubmit } = this.props;
    return (
      <form
        className="ui form error"
        style={style.form}
        onSubmit={handleSubmit(this.onSubmit)}
      >
        <h4 className="ui dividing header">
          <FormattedMessage id="organization.info" />
        </h4>
        <div style={style.twofields}>
          <div className="fourteen wide field">
            <Field
              name="orgName"
              label={intl.formatMessage({ id: "common.name" })}
              component={this.renderInput}
            />
          </div>
          <ColorPicker onChange={this.renderColor} />
        </div>
        <h4 className="ui dividing header">
          <FormattedMessage id="organization.adminInfo" />
        </h4>

        <Field
          name="name"
          label={intl.formatMessage({ id: "common.name" })}
          component={this.renderInput}
        />
        <Field
          name="email"
          label="Email"
          component={this.renderInput}
          type="text"
        />
        <Field
          name="password"
          label={intl.formatMessage({ id: "login.password" })}
          component={this.renderInput}
          type="password"
        />

        <div className="inline fields" style={style.twofields}>
          <div className="inline field">
            <Field
              className="ui checkbox"
              name="rememberMe"
              type="checkbox"
              component="input"
            />
            <label>
              <FormattedMessage id="login.rememberMe" />
            </label>
          </div>
          <div>
            <button
              className={
                this.state.isLoading ? "ui loading button" : `ui button`
              }
              style={{
                backgroundColor: this.state.backgroundColor,
                color: this.state.color,
              }}
            >
              <FormattedMessage id="common.add" />
            </button>

            <Link className="ui button" to="/login">
              {intl.formatMessage({ id: "common.cancel" })}
            </Link>
          </div>
        </div>
      </form>
    );
  }

  render() {
    const { intl } = this.props;
    return (
      <Modal
        title={intl.formatMessage({ id: "organization.title" })}
        content={this.renderContent()}
        isSignIn={true}
      />
    );
  }
}

const style = {
  form: {
    display: "flex",
    flexDirection: "column",
  },
  twofields: {
    display: "flex",
    justifyContent: "space-between",
  },
};

export default connect(null, { addNew })(
  reduxForm({ form: "newOrganization", validate })(injectIntl(NewOrganization))
);
