import { put, all, fork, takeLatest } from "redux-saga/effects";
import {
  WATCH_GET_REPORTS,
  ADD_REPORT,
  DELETE_REPORT,
  EDIT_REPORT,
} from "./types";
import { setReports } from "./actions";
import { db, reports } from "../../../services/firebase";
import history from "../../../history";

function* settingReports({ userId }) {
  try {
    const query = yield db
      .collection(reports)
      .where("usersId", "==", userId)
      .orderBy("date")
      .get();
    yield put(setReports(query));
  } catch (error) {
    yield alert(error);
  }
}

function* addingReport({ report }) {
  try {
    yield db.collection(reports).add({
      date: report.date,
      description: report.description,
      projectId: report.projectId,
      projectName: report.projectName,
      time: report.time,
      usersId: report.usersId,
    });
    history.push(`/dashboard/report/${report.usersId}`);
  } catch (error) {
    yield alert(error);
  }
}

function* delettingReport({ report }) {
  try {
    yield db.collection(reports).doc(report.id).delete();
    history.push(`/dashboard/report/${report.user}`);
  } catch (error) {
    yield alert(error);
  }
}

function* edditingReport({ report }) {
  try {
    const { projectId, projectName, time, description, user } = report;
    yield db
      .collection(reports)
      .doc(report.id)
      .update({ projectId, projectName, time, description });
    history.push(`/dashboard/report/${user}`);
  } catch (error) {
    yield alert(error);
  }
}

function* watchReports() {
  yield takeLatest(WATCH_GET_REPORTS, settingReports);
}

function* addReport() {
  yield takeLatest(ADD_REPORT, addingReport);
}

function* editReport() {
  yield takeLatest(EDIT_REPORT, edditingReport);
}

function* deleteReport() {
  yield takeLatest(DELETE_REPORT, delettingReport);
}

function* ReportsSaga() {
  yield all([
    fork(watchReports),
    fork(addReport),
    fork(editReport),
    fork(deleteReport),
  ]);
}

export default ReportsSaga;
