export const getStyle = (context) => {
  const style = {
    button: {
      backgroundColor: context.backgroundColor,
      color: context.color,
    },
    header: {
      marginTop: "1em",
      backgroundColor: context.backgroundColor,
      color: context.color,
    },
    color: {
      color: context.color,
    },
  };
  return style;
};
