import React from "react";
import Modal from "../../Modal";
import { injectIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { validate, renderError } from "../../utils/validations";
import { deleteAccount } from "../login/redux/actions";
import { Field, reduxForm } from "redux-form";

class Delete extends React.Component {
  state = { isLoading: false };
  renderContent = () => {
    const { handleSubmit } = this.props;
    return (
      <form className="ui error form" onSubmit={handleSubmit(this.onSubmit)}>
        <Field name="password" component={this.renderField} />

        <Link to="/dashboard" className="ui right floated button">
          <FormattedMessage id="common.cancel" />
        </Link>
        <button
          className={
            this.state.isLoading
              ? "ui right floated loading button"
              : "ui red right floated button"
          }
        >
          <FormattedMessage id="common.delete" />
        </button>
      </form>
    );
  };

  renderField = ({ input, meta }) => {
    return (
      <div className="required field">
        <label>
          <FormattedMessage id="delete.confirm" />
        </label>
        <input {...input} type="password" />
        {renderError(meta)}
      </div>
    );
  };

  onSubmit = ({ password }) => {
    this.props.deleteAccount(password);
    this.setState({ isLoading: true });
  };

  render() {
    const { intl } = this.props;
    return (
      <Modal
        title={intl.formatMessage({ id: "delete.title" })}
        content={this.renderContent()}
      />
    );
  }
}

export default connect(null, { deleteAccount })(
  reduxForm({ form: "deleteUser", validate })(injectIntl(Delete))
);
