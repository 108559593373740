import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ColorContext } from "../../../Root";
import { getStyle } from "../../../assets/styles/styles";
import { injectIntl } from "react-intl";

const style = {
  tddiv: {
    maxWidth: "20em",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cursor: {
    cursor: "pointer",
  },
};

const countProjects = (client, projects) => {
  let activeProjects = 0;
  if (client) {
    client.projects.forEach((project) => {
      projects.forEach((doc) => {
        if (project === doc.id && doc.hide === false) activeProjects++;
      });
    });
  }
  return activeProjects;
};

const Client = ({ client, viewProjects, projects, intl }) => {
  const context = useContext(ColorContext);
  return (
    <tr style={style.cursor} onClick={() => viewProjects(client)}>
      <td>{client.name}</td>
      <td>{client.mail}</td>
      <td>
        <div style={style.tddiv}>{client.notes}</div>
      </td>
      <td>{countProjects(client, projects)}</td>
      <td className="right aligned">
        <Link
          to={{
            pathname: `/clients/edit-client/${client.id}`,
            state: { isModal: true },
          }}
          className="ui icon label"
          style={getStyle(context).button}
        >
          <i className="edit outline icon" />
          {intl.formatMessage({ id: "common.edit" })}
        </Link>

        <Link
          to={{
            pathname: `/clients/add-project/${client.id}`,
            state: { isModal: true },
          }}
          className="ui icon label"
          style={getStyle(context).button}
        >
          <i className="plus icon" />
          {intl.formatMessage({ id: "clients.addProject" })}
        </Link>

        <Link
          to={{
            pathname: `/clients/delete-client/${client.id}`,
            state: { isModal: true },
          }}
          className="ui red icon label"
        >
          <i className="trash alternate icon" />
          {intl.formatMessage({ id: "common.delete" })}
        </Link>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  return {
    projects: state.projects,
  };
};

export default connect(mapStateToProps)(injectIntl(Client));
