import React, { useContext } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { FormattedDate, FormattedMessage } from "react-intl";
import { ColorContext } from "../../Root";
import { getStyle } from "../../assets/styles/styles";

import { dateFormat } from "../../utils/dateFormat";

const DataItem = ({ report, user }) => {
  const context = useContext(ColorContext);

  const date = moment(report.date, dateFormat);
  return (
    <tr>
      <td>
        <FormattedDate
          value={date}
          day="2-digit"
          month="2-digit"
          year="numeric"
        />
      </td>
      <td>{report.projectName}</td>
      <td>{report.description}</td>
      <td className="right aligned">
        <Link
          className="ui mini button"
          style={getStyle(context).button}
          to={{
            pathname: `/dashboard/report/${user}/edit/${report.id}`,
            state: { isModal: true },
          }}
        >
          <i className="edit outline icon" />
          <FormattedMessage id="common.edit" />
        </Link>
        <Link
          className="ui right floated mini red icon button"
          to={{
            pathname: `/dashboard/report/${user}/delete/${report.id}`,
            state: { isModal: true },
          }}
        >
          <i className="trash icon" />
        </Link>
      </td>
      <td className="right aligned">{report.time}</td>
    </tr>
  );
};

export default DataItem;
