import React from "react";
import Modal from "../../Modal";
import { ColorContext } from "../../Root";
import { getStyle } from "../../assets/styles/styles";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { renderError, validate } from "../../utils/validations";
import { changePassword } from "../login/redux/actions";
import { Field, reduxForm } from "redux-form";
import { FormattedMessage, injectIntl } from "react-intl";

class ChangePassword extends React.Component {
  state = { isLoading: false };
  static contextType = ColorContext;

  renderContent = () => {
    const { intl, handleSubmit, submitting } = this.props;

    return (
      <form className="ui error form" onSubmit={handleSubmit(this.onSubmit)}>
        <Field
          name="oldPassword"
          component={this.renderInput}
          label={intl.formatMessage({ id: "password.old" })}
        />

        <Field
          name="newPassword"
          component={this.renderInput}
          label={intl.formatMessage({ id: "password.new" })}
        />

        <Field
          name="confirmPassword"
          component={this.renderInput}
          label={intl.formatMessage({ id: "password.confirm" })}
        />

        <Link to="/dashboard" className="ui right floated button">
          <FormattedMessage id="common.cancel" />
        </Link>
        <button
          className={
            this.state.isLoading
              ? "ui right floated loading button"
              : "ui right floated button"
          }
          style={getStyle(this.context).button}
          disabled={submitting}
        >
          <FormattedMessage id="common.save" />
        </button>
      </form>
    );
  };

  renderInput = ({ input, meta, label }) => {
    return (
      <div className="required field">
        <label>{label}</label>
        <input {...input} type="password" />
        {renderError(meta)}
      </div>
    );
  };

  onSubmit = ({ oldPassword, confirmPassword }) => {
    this.props.changePassword({ oldPassword, confirmPassword });
  };

  render() {
    const { intl } = this.props;
    return (
      <Modal
        title={intl.formatMessage({ id: "password.title" })}
        content={this.renderContent()}
      />
    );
  }
}

export default connect(null, { changePassword })(
  reduxForm({ form: "changePassword", validate })(injectIntl(ChangePassword))
);
