import React from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { validate } from "../../utils/validations";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { restorePassword } from "./redux/actions";
import Modal from "../../Modal";

class RestorePassword extends React.Component {
  renderInput = ({ meta, label, input }) => {
    return (
      <div className="required field">
        <label>{label}</label>
        <input {...input} />
        {this.renderError(meta)}
      </div>
    );
  };

  renderError({ error, touched }) {
    if (error && touched) {
      return <div className="ui mini error message">{error}</div>;
    }
    return (
      <div className="ui mini message">
        <FormattedMessage id="restore.message" />
      </div>
    );
  }

  onSubmit = ({ email }) => {
    this.props.restorePassword(email);
  };

  renderContent() {
    const { handleSubmit } = this.props;
    return (
      <form className="ui form error" onSubmit={handleSubmit(this.onSubmit)}>
        <Field name="email" label="Email" component={this.renderInput} />
        <Link className="ui right floated button" to="./">
          <FormattedMessage id="common.cancel" />
        </Link>
        <button className={`ui blue right floated button`}>
          <FormattedMessage id="restore.button" />
        </button>
      </form>
    );
  }

  render() {
    const { intl } = this.props;
    return (
      <Modal
        title={intl.formatMessage({ id: "restore.title" })}
        content={this.renderContent()}
        isSignIn={true}
      />
    );
  }
}

export default connect(null, { restorePassword })(
  reduxForm({ form: "restoreForm", validate })(injectIntl(RestorePassword))
);
