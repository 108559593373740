import {
  WATCH_GET_USERS,
  SET_USERS,
  ADD_USER,
  DELETE_USER,
  EDIT_USER,
} from "./types";

export const getUsers = (searchTerm) => ({
  type: WATCH_GET_USERS,
  searchTerm,
});

export const setUsers = (query, searchTerm) => ({
  type: SET_USERS,
  query,
  searchTerm,
});

export const addUser = (newUser) => ({
  type: ADD_USER,
  newUser,
});

export const editUser = (user) => ({
  type: EDIT_USER,
  user,
});

export const deleteUser = (userId) => ({
  type: DELETE_USER,
  userId,
});
