import React from "react";
import Modal from "../../../Modal";
import { ColorContext } from "../../../Root";
import { getStyle } from "../../../assets/styles/styles";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { validate, renderError } from "../../../utils/validations";
import { addClients } from "../redux/actions";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";

class AddClient extends React.Component {
  state = { isLoading: false };
  static contextType = ColorContext;
  renderContent = () => {
    const { intl, handleSubmit } = this.props;

    return (
      <form className="ui form error" onSubmit={handleSubmit(this.onSubmit)}>
        <Field
          name="name"
          label={intl.formatMessage({ id: "common.name" })}
          component={this.renderInput}
        />
        <Field name="email" label="Email" component={this.renderInput} />
        <Field
          name="notes"
          label={intl.formatMessage({ id: "clients.notes" })}
          component={this.renderTextArea}
        />

        <Link to="/clients/" className="ui right floated button">
          <FormattedMessage id="common.cancel" />
        </Link>
        <button
          className={
            this.state.isLoading
              ? "ui right floated loading button"
              : "ui right floated button"
          }
          style={getStyle(this.context).button}
        >
          <FormattedMessage id="clients.add" />
        </button>
      </form>
    );
  };

  renderInput = ({ meta, label, input }) => {
    return (
      <div className="field">
        <label>{label}</label>
        <input {...input} />
        {renderError(meta)}
      </div>
    );
  };

  renderTextArea = ({ label, input }) => {
    return (
      <div className="field">
        <label>{label}</label>
        <textarea {...input} rows="2" />
      </div>
    );
  };

  onSubmit = ({ name, email, notes }) => {
    const { user, addClients } = this.props;
    const organizationId = user.organizationId;
    const client = { name, mail: email, projects: [], notes, organizationId };
    addClients(client);
    this.setState({ isLoading: true });
  };

  render() {
    const { intl } = this.props;
    return (
      <Modal
        title={intl.formatMessage({ id: "clients.addClients" })}
        content={this.renderContent()}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, { addClients })(
  reduxForm({ form: "addForm", validate })(injectIntl(AddClient))
);
