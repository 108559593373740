import React from "react";
import GuardRoute from "./guardRoute";
import Password from "./components/Header/Password";
import Delete from "./components/Header/Delete";
import DeleteUser from "./components/Dashboard/Users/DeleteUser";
import AddUser from "./components/Dashboard/Users/AddUser";
import Message from "./components/Dashboard/Message";
import EditUser from "./components/Dashboard/Users/EditUser";
import AddReport from "./components/Report/AddReport";
import DeleteItem from "./components/Report/DeleteItem";
import EditReport from "./components/Report/EditItem";
import AddClient from "./components/Projects/Clients/AddClient";
import EditClient from "./components/Projects/Clients/EditClient";
import DeleteClient from "./components/Projects/Clients/DeleteClient";
import DeleteProject from "./components/Projects//Projects/DeleteProject";
import ProjectUsers from "./components/Projects/Projects/ProjectUsers";
import AddProject from "./components/Projects/Projects/AddProject";
import EditProject from "./components/Projects/Projects/EditProject";

const ModalRoutes = () => (
  <React.Fragment>
    <GuardRoute
      type="private"
      exact
      path="/dashboard/add-user/"
      component={AddUser}
    />
    <GuardRoute
      type="private"
      exact
      path="/dashboard/message"
      component={Message}
    />
    <GuardRoute type="private" exact path="/password" component={Password} />
    <GuardRoute type="private" path="/delete" exact component={Delete} />

    <GuardRoute
      type="private"
      exact
      path="/dashboard/report/add/:id/:date"
      component={AddReport}
    />
    <GuardRoute
      type="private"
      exact
      path="/dashboard/report/:user/delete/:report"
      component={DeleteItem}
    />
    <GuardRoute
      type="private"
      exact
      path="/dashboard/report/:user/edit/:report"
      component={EditReport}
    />
    <GuardRoute
      type="private"
      exact
      path="/dashboard/delete/:id"
      component={DeleteUser}
    />
    <GuardRoute
      type="private"
      exact
      path="/dashboard/edit/:id"
      component={EditUser}
    />
    <GuardRoute
      type="private"
      exact
      path="/users/add-user/"
      component={AddUser}
    />
    <GuardRoute
      type="private"
      exact
      path="/clients/add-client"
      component={AddClient}
    />
    <GuardRoute
      type="private"
      exact
      path="/clients/edit-client/:id"
      component={EditClient}
    />
    <GuardRoute
      type="private"
      exact
      path="/clients/delete-client/:id"
      component={DeleteClient}
    />
    <GuardRoute
      type="private"
      exact
      path="/clients/add-project/:id"
      component={AddProject}
    />
    <GuardRoute
      type="private"
      exact
      path="/clients/project/edit-project/:id"
      component={EditProject}
    />
    <GuardRoute
      type="private"
      path="/clients/project/users/:id"
      component={ProjectUsers}
    />
    <GuardRoute
      type="private"
      exact
      path="/clients/project/delete-project/:id"
      component={DeleteProject}
    />
  </React.Fragment>
);

export default ModalRoutes;
