import React from "react";
import Modal from "../../Modal";
import { Link } from "react-router-dom";
import { ColorContext } from "../../Root";
import { getStyle } from "../../assets/styles/styles";

import { FormattedMessage, injectIntl } from "react-intl";

class Message extends React.Component {
  static contextType = ColorContext;
  renderActions = () => {
    return (
      <React.Fragment>
        <button className={`ui button`} style={getStyle(this.context).button}>
          <FormattedMessage id="message.send" />
        </button>
        <Link to="/dashboard/" className="ui button">
          <FormattedMessage id="common.cancel" />
        </Link>
      </React.Fragment>
    );
  };

  renderContent = () => {
    return (
      <form className="ui form">
        <div className="field">
          <textarea />
        </div>
      </form>
    );
  };

  render() {
    const { intl } = this.props;

    return (
      <Modal
        title={intl.formatMessage({ id: "message.sendMessage" })}
        content={this.renderContent()}
        actions={this.renderActions()}
      />
    );
  }
}

export default injectIntl(Message);
