import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logOut } from "../login/redux/actions";
import { FormattedMessage } from "react-intl";
import { ColorContext } from "../../Root";
import { getStyle } from "../../assets/styles/styles";

const renderProjectsButton = (user, context) => {
  if (user.isAdmin) {
    return (
      <Link to="/clients" className="item" style={getStyle(context).color}>
        <h5>
          <FormattedMessage id="dashboard.header.projects" />
        </h5>
      </Link>
    );
  } else {
    return null;
  }
};

const Header = (props) => {
  const context = useContext(ColorContext);
  const { logOut, user } = props;
  return (
    <div className="ui menu" style={getStyle(context).header}>
      <Link to="/dashboard" className="item" style={getStyle(context).color}>
        <i className="home icon"></i>
      </Link>
      {renderProjectsButton(user, context)}
      <div className="right compact menu">
        <div
          className="ui simple dropdown item"
          style={getStyle(context).color}
        >
          <i className="user icon" />
          {user.displayName}
          <i className="dropdown icon" />
          <div className="menu">
            <label className="item">{user.email}</label>
            <div className="divider" />
            <Link
              to={{ pathname: "/password", state: { isModal: true } }}
              className="item"
            >
              <FormattedMessage id="dashboard.header.changePassword" />
            </Link>
            <Link
              to={{ pathname: "/delete", state: { isModal: true } }}
              className="item"
            >
              <FormattedMessage id="dashboard.header.deleteAccount" />
            </Link>
            <div className="divider" />
            <label className="item" onClick={logOut}>
              <FormattedMessage id="dashboard.header.signOut" />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, { logOut })(Header);
