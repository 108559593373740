import React from "react";
import Modal from "../../../Modal";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { deleteProject } from "../redux/actions";
import { FormattedMessage, injectIntl } from "react-intl";

class DeleteProject extends React.Component {
  deleteProject = () => {
    const { match, deleteProject } = this.props;
    const project = match.params.id;
    deleteProject(project);
  };

  renderActions = () => {
    return (
      <React.Fragment>
        <button className="ui red button" onClick={() => this.deleteProject()}>
          <FormattedMessage id="common.delete" />
        </button>
        <Link to="/clients" className="ui button">
          <FormattedMessage id="common.cancel" />
        </Link>
      </React.Fragment>
    );
  };

  render() {
    const { intl } = this.props;
    return (
      <Modal
        title={intl.formatMessage({ id: "deleteProject.title" })}
        content={intl.formatMessage({ id: "deleteProject.message" })}
        actions={this.renderActions()}
      />
    );
  }
}
export default connect(null, { deleteProject })(
  injectIntl(injectIntl(DeleteProject))
);
