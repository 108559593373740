import LanguageDetector from "i18next-browser-languagedetector";

const languageDetector = new LanguageDetector(null, navigator);
export const setLanguage = () => {
  const currentLocale = languageDetector.options.language;
  if (currentLocale.startsWith("es")) {
    return "es-MX";
  } else if (currentLocale.startsWith("en")) {
    return "en-US";
  } else {
    return "en-US";
  }
};

export const translations = {
  "es-MX": {
    "dashboard.header.projects": "Proyectos",
    "dashboard.header.changePassword": "Cambiar Contraseña",
    "dashboard.header.deleteAccount": "Borrar cuenta",
    "dashboard.header.signOut": "Cerrar Sesión",
    "dashboard.users": "Usuarios",
    "dashboard.sendMessage": "Enviar mensaje",
    "dashboard.addNew": "Agregar usuario",
    "dashboard.editTitle": "Editar usuario",
    "dashboard.searchBar": "Buscar usuarios...",
    "deleteUser.title": "Borrar Usuario",
    "common.report": "Reporte",
    "dashboard.delete": "¿Esta seguro que desea eliminar a este usuario?",
    "dashboard.basicUser": "Usuario Básico",
    "dashboard.admin": "Administrador",
    "dashboard.noUsers": "No se encontraron usuarios para esta busqueda",
    "message.send": "Enviar",
    "message.sendMessage": "Enviar mensaje",
    "clients.label": "Clientes",
    "clients.addClient": "Agregar cliente",
    "clients.add": "Agregar",
    "clients.search": "Buscar clientes...",
    "clients.noClients": "No se encontraron clientes para esta busqueda",
    "clients.addClients": "Agregar un cliente",
    "clients.notes": "Notas",
    "clients.delete": "Borrar Cliente",
    "clients.save": "Guardar",
    "clients.edit": "Editar cliente",
    "clients.message": "¿Está seguro de borrar este cliente?",
    "clients.addProject": "Agregar Proyecto",
    "clients.activeProjects": "Proyectos",
    "delete.confirm": "Ingrese contraseña para confirmar",
    "delete.title": "Borrar cuenta",
    "projects.for": "Proyectos de",
    "projects.project": "Proyecto",
    "deleteProject.message": "¿Esta seguro que desea borrar este proyecto?",
    "deleteProject.title": "Borrar Proyecto",
    "projects.users": "Usuarios",
    "projects.noProjects": "No existen proyectos para este cliente",
    "password.old": "Contraseña anterior",
    "password.new": "Nueva contraseña",
    "password.confirm": "Repita la nueva contraseña",
    "password.title": "Cambiar Contraseña",
    "addProject.title": "Agregar Proyecto",
    "addProject.name": "Nombre del proyecto",
    "editProject.title": "Editar Proyecto",
    "common.add": "Agregar",
    "common.save": "Guardar",
    "common.cancel": "Cancelar",
    "common.name": "Nombre",
    "common.edit": "Editar",
    "common.delete": "Borrar",
    "common.type": "Tipo",
    "common.users": "Usuarios",
    "common.project": "Proyecto",
    "common.description": "Descripción",
    "addUser.addUser": "Agregar Usuario",
    "addUser.add": "Agregar",
    "report.noUsers": "No se encontraron reportes para este usuario.",
    "report.day": "Día",
    "report.month": "Mes",
    "report.week": "Semana",
    "report.date": "Fecha",
    "report.hours": "Horas",
    "report.minutes": "Minutos",
    "deleteItem.message": "¿Esta seguro que desea borrar este reporte?",
    "deleteItem.title": "Borrar Reporte",
    "errors.noName": "Debes ingresar un nombre",
    "errors.noEmail": "Debes ingresar un email",
    "errors.noPassword": "Debes ingresar una contraseña",
    "errors.validEmail": "Debes ingresar un email valido",
    "errors.notMatch": "Las contraseñas no coinciden",
    "errors.required": "Campo Requerido",
    "errors.number": "Debe ser un numero",
    "errors.outRange": "Fuera de rango",
    "errors.project": "Debes seleccionar un proyecto",
    "errors.noDescription": "Debes ingresar una descripción",
    "errors.noProject": "Debes seleccionar un proyecto",
    "addReport.title": "Cargar Reporte",
    "addReport.date": "Fecha",
    "addReport.hours": "Horas",
    "addReport.minutes": "Minutos",
    "addReport.select": "Selecciona un proyecto...",
    "addReport.noProjects": "No tienes proyectos asignados",
    "editReport.title": "Editar Reporte",
    "login.rememberMe": "Recuérdame",
    "login.login": "Ingresar",
    "login.password": "Contraseña",
    "login.forgot": "¿Olvidó su contraseña?",
    "login.organization":
      "¿Quieres agregar tu propia organización? Clickea aquí.",
    "organization.title": "Nueva organización",
    "organization.info": "Información de la organización",
    "organization.adminInfo": "Información del administrador",
    "restore.title": "Recuperar contraseña",
    "restore.button": "Recuperar",
    "restore.message":
      "Ingrese la cuenta de email con la cual esta registrado, luego siga las instrucciones en su casilla de correo",
  },

  "en-US": {
    "dashboard.header.projects": "Projects",
    "dashboard.header.changePassword": "Change Password",
    "dashboard.header.deleteAccount": "Delete account",
    "dashboard.header.signOut": "Sign Out",
    "dashboard.users": "Users",
    "dashboard.sendMessage": "Send message",
    "dashboard.addNew": "Add User",
    "dashboard.editTitle": "Edit user",
    "dashboard.searchBar": "Search Users...",
    "deleteUser.title": "Delete User",
    "common.report": "Report",
    "dashboard.delete": "Are you sure you want to delete this user?",
    "dashboard.basicUser": "Basic User",
    "dashboard.admin": "Administrator",
    "dashboard.noUsers": "No users for this search terms",
    "message.send": "Send",
    "message.sendMessage": "Send message",
    "clients.label": "Clients",
    "clients.addClient": "Add client...",
    "clients.add": "Add",
    "clients.search": "Search clients...",
    "clients.noClients": "No clients for this search terms",
    "clients.addClients": "Add Client",
    "clients.notes": "Notes",
    "clients.delete": "Delete Client",
    "clients.save": "Save",
    "clients.edit": "Edit client",
    "clients.message": "¿Are you sure you want to delete this client?",
    "clients.addProject": "Add Project",
    "clients.activeProjects": "Projects",
    "delete.confirm": "Please type your password to confirm",
    "delete.title": "Delete Account",
    "projects.for": "Projects from",
    "projects.project": "Project",
    "deleteProject.message": "¿Are you sure you want to delete this project?",
    "deleteProject.title": "Delete Project",
    "projects.users": "Users",
    "projects.noProjects": "No projects for this client",
    "password.old": "Last Password",
    "password.new": "New Password",
    "password.confirm": "Repeat new password",
    "password.title": "Change password",
    "addProject.title": "Add Project",
    "addProject.name": "Project name",
    "editProject.title": "Edit project",
    "common.add": "Add",
    "common.save": "Save",
    "common.cancel": "Cancel",
    "common.name": "Name",
    "common.edit": "Edit",
    "common.delete": "Delete",
    "common.type": "Type",
    "common.users": "Users",
    "common.project": "Project",
    "common.description": "Description",
    "addUser.addUser": "Add User",
    "addUser.add": "Add",
    "report.noUsers": "No reports for this user",
    "report.day": "Day",
    "report.month": "Month",
    "report.week": "Week",
    "report.date": "Date",
    "report.hours": "Hours",
    "report.minutes": "Minutes",
    "deleteItem.message": "Are you sure you want to delete this report?",
    "deleteItem.title": "Delete Report",
    "errors.noName": "Name required",
    "errors.noEmail": "Email required",
    "errors.noPassword": "Password required",
    "errors.validEmail": "Insert a valid Email",
    "errors.notMatch": "Passwords do not match",
    "errors.required": "Required",
    "errors.number": "Must be a number",
    "errors.outRange": "Out of range",
    "errors.project": "Must select a project",
    "errors.noDescription": "You must enter a description",
    "errors.noProject": "You must select a project",
    "addReport.title": "Add a report",
    "addReport.date": "Date",
    "addReport.hours": "Hours",
    "addReport.minutes": "Minutes",
    "addReport.select": "Select a project...",
    "addReport.noProjects": "You have no assigned projects",
    "editReport.title": "Edit Report",
    "login.rememberMe": "Remember me",
    "login.login": "Login",
    "login.password": "Password",
    "login.forgot": "Forgot your password?",
    "login.organization": "Want to add your own organization? Click Here.",
    "organization.title": "Add a new organization",
    "organization.info": "Organization Info",
    "organization.adminInfo": "Administrator Info",
    "restore.title": "Restore password",
    "restore.button": "Restore",
    "restore.message":
      "Enter the email account with which you are registered, then follow the instructions in your mailbox",
  },
};
