import React from "react";
import { withRouter, Switch, Redirect } from "react-router-dom";

import GuardRoute from "./guardRoute";
import ModalRoutes from "./ModalRoutes";

import Login from "./components/login/index";
import RestorePassword from "./components/login/restore";
import NewOrganization from "./components/login/newOrganization";
import Dashboard from "./components/Dashboard";
import Report from "./components/Report";
import Projects from "./components/Projects";

const defaultLocation = { pathname: "/dashboard" };
const regex = /clients|dashboard|password|delete/i;

class Routes extends React.Component {
  componentDidUpdate() {
    const { location } = this.props;
    if (this.shouldUpdatePreviousLocation()) {
      this.previousLocation = location;
    }
  }

  isModal = () => {
    const { location } = this.props;
    if (!location.state) return false;
    return location.state.isModal;
  };

  shouldUsePreviousLocation = () => {
    const { location } = this.props;
    if (!location) return false;
    return this.isModal() && regex.test(location.pathname);
  };

  shouldUpdatePreviousLocation = () => {
    const { location } = this.props;
    if (!location) return false;
    return regex.test(location.pathname);
  };

  render() {
    const { location } = this.props;
    const usePreviousLocation = this.shouldUsePreviousLocation();
    let forcedLocation;

    if (usePreviousLocation) {
      forcedLocation = this.previousLocation || defaultLocation;
    } else {
      forcedLocation = location;
    }

    return (
      <React.Fragment>
        <Switch location={forcedLocation}>
          <GuardRoute type="public" exact path="/login" component={Login} />
          <GuardRoute
            type="public"
            exact
            path="/restore"
            component={RestorePassword}
          />
          <GuardRoute
            type="public"
            exact
            path="/newOrganization"
            component={NewOrganization}
          />
          <GuardRoute
            type="private"
            exact
            path="/dashboard"
            component={Dashboard}
          />
          <GuardRoute
            type="private"
            exact
            path="/dashboard/report/:id"
            component={Report}
          />
          <GuardRoute
            type="private"
            exact
            path="/clients"
            component={Projects}
          />
          <ModalRoutes />
          <Redirect from="/" to="/dashboard" />
        </Switch>

        {usePreviousLocation && <ModalRoutes />}
      </React.Fragment>
    );
  }
}

export default withRouter(Routes);
