import React from "react";
import ReactDOM from "react-dom";
import LoginForm from "./LoginForm";
import { connect } from "react-redux";
import { logIn, getUserData } from "./redux/actions";
import { watchUserChanges } from "../../services/firebase";

class login extends React.Component {
  state = { isLoading: true };
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      watchUserChanges((user) => {
        user
          ? this.props.getUserData(user)
          : this.setState({ isLoading: false });
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSubmit = (formValues) => {
    this.props.logIn(formValues);
  };

  render() {
    if (this.state.isLoading) {
      return (
        <div className="ui basic segment">
          <div className="ui active inverted dimmer" style={style.loading}>
            <div className="ui indeterminate large loader" />
          </div>
        </div>
      );
    } else {
      return ReactDOM.createPortal(
        <LoginForm onSubmit={this.onSubmit} />,
        document.querySelector("#modal")
      );
    }
  }
}

const style = {
  loading: {
    height: "100%",
    width: "100%",
    position: "fixed",
    backgroundColor: "lightgray",
  },
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, {
  logIn,
  getUserData,
})(login);
