import { SET_REPORTS, CLEAR_REPORTS_DATA } from "./types";

const reportsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_REPORTS:
      let reports = [];
      action.query.forEach((doc) => {
        reports = [
          ...reports,
          {
            id: doc.id,
            date: doc.data().date,
            description: doc.data().description,
            projectId: doc.data().projectId,
            projectName: doc.data().projectName,
            time: doc.data().time,
            usersId: doc.data().usersId,
          },
        ];
      });
      if (reports.length) {
        return reports;
      } else {
        return false;
      }

    case CLEAR_REPORTS_DATA:
      let empty = [];
      return empty;

    default:
      return state;
  }
};

export default reportsReducer;
