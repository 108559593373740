import { takeLatest, put, all, fork } from "redux-saga/effects";
import {
  WATCH_GET_PROJECTS,
  ADD_PROJECT,
  EDIT_PROJECT,
  DELETE_PROJECT,
  EDIT_USERSPROJECT,
} from "./types";
import { setProjects } from "./actions";
import { db, clients, projects } from "../../../services/firebase";
import history from "../../../history";

function* settingProjects() {
  try {
    const query = yield db
      .collection(projects)
      .where("hide", "==", false)
      .get();
    yield put(setProjects(query));
  } catch (error) {
    yield alert(error);
  }
}

function* addingProject({ project, client }) {
  try {
    if (!project.description) {
      project.description = "";
    }
    const docRef = yield db.collection(projects).add({
      name: project.projectName,
      desc: project.description,
      clientId: client.id,
      usersId: [],
      hide: false,
    });
    yield db
      .collection(clients)
      .doc(client.id)
      .update({ projects: [...client.projects, docRef.id] });
    history.push("/clients");
    yield put({ type: WATCH_GET_PROJECTS });
  } catch (error) {
    yield alert(error);
    history.push("/clients");
  }
}

function* edditingProject({ project }) {
  try {
    yield db
      .collection(projects)
      .doc(project.id)
      .update({ name: project.projectName, desc: project.description });
    history.push("/clients");
    yield put({ type: WATCH_GET_PROJECTS });
  } catch (error) {
    yield alert(error);
    history.push("/clients");
  }
}

function* edditingUsersProject({ users, projectId }) {
  try {
    yield db
      .collection(projects)
      .doc(projectId)
      .update({ usersId: users.usersId });
    history.push("/clients");
    yield put({ type: WATCH_GET_PROJECTS });
  } catch (error) {
    yield alert(error);
  }
}

function* delettingProject({ project }) {
  try {
    yield db.collection(projects).doc(project).update({ hide: true });
    history.push("/clients");
    yield put({ type: WATCH_GET_PROJECTS });
  } catch (error) {
    yield alert(error);
  }
}

function* watchProjects() {
  yield takeLatest(WATCH_GET_PROJECTS, settingProjects);
}

function* addProject() {
  yield takeLatest(ADD_PROJECT, addingProject);
}

function* editProject() {
  yield takeLatest(EDIT_PROJECT, edditingProject);
}

function* editUsersProject() {
  yield takeLatest(EDIT_USERSPROJECT, edditingUsersProject);
}

function* deleteProject() {
  yield takeLatest(DELETE_PROJECT, delettingProject);
}

function* ProjectSaga() {
  yield all([
    fork(watchProjects),
    fork(addProject),
    fork(editProject),
    fork(editUsersProject),
    fork(deleteProject),
  ]);
}

export default ProjectSaga;
