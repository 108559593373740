import { takeLatest, put } from "redux-saga/effects";
import {
  LOG_IN,
  LOG_OUT,
  CHANGE_PASSWORD,
  DELETE_ACCOUNT,
  RESTORE_PASSWORD,
  GET_USER_DATA,
  ADD_NEW,
} from "./types";
import { clearUserData, setUserData } from "./actions";
import {
  db,
  auth,
  persistence,
  getCredential,
  organization,
  users,
} from "../../../services/firebase";
import history from "../../../history";

function* loggingIn({ formValues }) {
  try {
    const { email, password, rememberMe } = formValues;
    if (rememberMe) {
      yield persistence("LOCAL");
    } else {
      yield persistence("SESSION");
    }
    yield auth.signInWithEmailAndPassword(email, password);
  } catch (error) {
    yield alert(error.code + "\n" + error.message);
    history.push("/dashboard");
  }
}

function* loggingOut() {
  try {
    yield auth.signOut();
    yield put(clearUserData());
  } catch (error) {
    yield alert(error);
  }
}

function* addingNew({ newOrg }) {
  try {
    const {
      orgName,
      color,
      name,
      email,
      password,
      rememberMe,
      textColor,
    } = newOrg;
    if (rememberMe) {
      yield persistence("LOCAL");
    } else {
      yield persistence("SESSION");
    }
    yield auth.createUserWithEmailAndPassword(email, password);
    const docRef = yield db
      .collection(organization)
      .add({ name: orgName, color, textColor });
    yield db.collection(users).doc(auth.currentUser.uid).set({
      displayName: name,
      isAdmin: true,
      organizationId: docRef.id,
      email,
      hide: false,
    });
    yield auth.currentUser.updateProfile({
      displayName: name,
    });
    history.push("/dashboard");
  } catch (error) {
    yield alert(error);
    history.push("/login");
  }
}

function* settingUserData({ user }) {
  try {
    const fetchUser = yield db.collection(users).doc(user.id).get();
    const fetchOrg = yield db
      .collection(organization)
      .doc(fetchUser.data().organizationId)
      .get();
    user = yield {
      ...user,
      isAdmin: fetchUser.data().isAdmin,
      organizationId: fetchUser.data().organizationId,
      color: fetchOrg.data().color,
      textColor: fetchOrg.data().textColor,
      name: fetchOrg.data().name,
    };
    yield put(setUserData(user));
  } catch (error) {
    yield put(clearUserData());
  }
}

function* changingPassword({ passwords }) {
  try {
    const { oldPassword, confirmPassword } = passwords;
    const credential = getCredential(oldPassword);
    let onSuccess = false;
    yield (onSuccess = auth.currentUser.reauthenticateWithCredential(
      credential
    ));
    if (onSuccess) {
      yield auth.currentUser.updatePassword(confirmPassword);
      yield auth.signOut();
      yield put(clearUserData());
    }
  } catch (error) {
    yield alert(error);
    history.push("/password");
  }
}

function* restoringPassword({ email }) {
  try {
    yield auth.sendPasswordResetEmail(email);
    yield history.push("/dashboard");
  } catch (error) {
    yield alert(error);
  }
}

function* delettingAccount({ password }) {
  try {
    const credential = getCredential(password);
    let onSuccess = false;
    yield (onSuccess = auth.currentUser.reauthenticateWithCredential(
      credential
    ));
    if (onSuccess) {
      yield db
        .collection(users)
        .doc(auth.currentUser.uid)
        .update({ hide: true });
      yield auth.currentUser.delete();
      yield auth.signOut();
      yield put(clearUserData());
    }
  } catch (error) {
    yield alert(error);
  }
}

function* userManager() {
  yield takeLatest(LOG_IN, loggingIn);
  yield takeLatest(LOG_OUT, loggingOut);
  yield takeLatest(ADD_NEW, addingNew);
  yield takeLatest(GET_USER_DATA, settingUserData);
  yield takeLatest(CHANGE_PASSWORD, changingPassword);
  yield takeLatest(RESTORE_PASSWORD, restoringPassword);
  yield takeLatest(DELETE_ACCOUNT, delettingAccount);
}

export default userManager;
