import React from "react";
import Project from "./Project";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import "../../../assets/css/tables.css";

class ProjectsList extends React.Component {
  renderProjects = (projects) => {
    if (projects.length) {
      return projects.map((project) => {
        return <Project key={project.id} project={project} />;
      });
    } else {
      return (
        <tr>
          <td>
            <FormattedMessage id="projects.noProjects" />
          </td>
        </tr>
      );
    }
  };

  render() {
    return (
      <table className="ui fixed table">
        <thead>
          <tr>
            <th className="wd20">
              <FormattedMessage id="projects.project" />
            </th>
            <th className="wd40">
              <FormattedMessage id="common.description" />
            </th>
            <th className="wd10">
              <FormattedMessage id="projects.users" />
            </th>
            <th className="wd30"></th>
          </tr>
        </thead>
        <tbody>{this.renderProjects(this.props.projects)}</tbody>
      </table>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let projectsClient;
  if (ownProps) {
    projectsClient = state.projects.filter(
      (project) => project.clientId === ownProps.id
    );
  }
  return {
    projects: projectsClient,
  };
};

export default connect(mapStateToProps)(ProjectsList);
