import UsersSaga from "../components/Dashboard/Users/redux/UsersSaga";
import ClientsSaga from "../components/Projects/redux/ClientsSaga";
import loginSaga from "../components/login/redux/loginSaga";
import ProjectSaga from "../components/Projects/redux/ProjectSaga";
import ReportSaga from "../components/Report/redux/reportSaga";

import { fork, all } from "redux-saga/effects";

function* RootSaga() {
  yield all([
    fork(UsersSaga),
    fork(loginSaga),
    fork(ClientsSaga),
    fork(ProjectSaga),
    fork(ReportSaga),
  ]);
}

export default RootSaga;
