import { LOG_IN, LOG_OUT, SET_USER_DATA, CLEAR_USER_DATA } from "./types";

const initialState = {
  uid: "",
  displayName: "",
  isAdmin: false,
  organizationId: "",
  color: "",
  textColor: "",
  isLogged: false,
  authReady: true,
};

const loginReducer = (state = initialState, { type, user }) => {
  switch (type) {
    case LOG_IN:
      return {
        ...state,
        authReady: false,
      };

    case SET_USER_DATA:
      return {
        ...state,
        uid: user.id,
        displayName: user.displayName,
        isAdmin: user.isAdmin,
        email: user.email,
        organizationId: user.organizationId,
        color: user.color,
        textColor: user.textColor,
        isLogged: true,
        authReady: true,
      };

    case LOG_OUT:
      return {
        ...state,
      };

    case CLEAR_USER_DATA:
      return {
        ...state,
        uid: "",
        displayName: "",
        isAdmin: false,
        email: "",
        organizationId: "",
        color: "",
        textColor: "",
        isLogged: false,
        authReady: true,
      };

    default:
      return state;
  }
};

export default loginReducer;
