import React from "react";
import { FormattedMessage } from "react-intl";

export const isEmailFormatValid = (field) => {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i.test(field);
};

export const validate = ({
  email,
  password,
  orgName,
  name,
  oldPassword,
  newPassword,
  confirmPassword,
  hours,
  minutes,
  projectName,
  displayName,
}) => {
  const errors = {};
  if (!email) {
    errors.email = <FormattedMessage id="errors.noEmail" />;
  } else if (isEmailFormatValid(email)) {
    errors.email = <FormattedMessage id="errors.validEmail" />;
  }
  if (!password) {
    errors.password = <FormattedMessage id="errors.noPassword" />;
  }
  if (!name) {
    errors.name = <FormattedMessage id="errors.noName" />;
  }
  if (!displayName) {
    errors.displayName = <FormattedMessage id="errors.noName" />;
  }
  if (!orgName) {
    errors.orgName = <FormattedMessage id="errors.noName" />;
  }
  if (!password) {
    errors.password = <FormattedMessage id="errors.noPassword" />;
  }
  if (!oldPassword) {
    errors.oldPassword = <FormattedMessage id="errors.noPassword" />;
  }
  if (!newPassword) {
    errors.newPassword = <FormattedMessage id="errors.noPassword" />;
  }
  if (newPassword !== confirmPassword) {
    errors.confirmPassword = <FormattedMessage id="errors.notMatch" />;
  }
  if (hours < 0 || hours > 23) {
    errors.hours = <FormattedMessage id="errors.outRange" />;
  }
  if (minutes < 0 || minutes > 59) {
    errors.minutes = <FormattedMessage id="errors.outRange" />;
  }
  if (!projectName) {
    errors.projectName = <FormattedMessage id="errors.noName" />;
  }
  return errors;
};

export const renderError = ({ error, touched }) => {
  if (error && touched) {
    return <div className="ui mini error message">{error}</div>;
  }
};
