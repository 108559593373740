import React from "react";
import Modal from "../../../Modal";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { validate, renderError } from "../../../utils/validations";
import { editClient } from "../redux/actions";
import { ColorContext } from "../../../Root";
import { getStyle } from "../../../assets/styles/styles";

import { injectIntl, FormattedMessage } from "react-intl";

class EditClient extends React.Component {
  state = { isLoading: false };
  static contextType = ColorContext;
  renderContent = () => {
    const { intl, handleSubmit, pristine } = this.props;

    return (
      <form className="ui form error" onSubmit={handleSubmit(this.onSubmit)}>
        <Field
          name="name"
          label={intl.formatMessage({ id: "common.name" })}
          component={this.renderInput}
        />
        <Field name="email" label="Email:" component={this.renderInput} />
        <Field
          name="notes"
          label={intl.formatMessage({ id: "clients.notes" })}
          component={this.renderTextArea}
        />
        <Link to="/clients/" className="ui right floated button">
          <FormattedMessage id="common.cancel" />
        </Link>
        <button
          className={
            this.state.isLoading
              ? "ui right floated loading button"
              : "ui right floated button"
          }
          style={getStyle(this.context).button}
          disabled={pristine ? true : false}
        >
          <FormattedMessage id="clients.save" />
        </button>
      </form>
    );
  };

  renderInput = ({ meta, label, input }) => {
    return (
      <div className="field">
        <label>{label}</label>
        <input {...input} />
        {renderError(meta)}
      </div>
    );
  };

  renderTextArea = ({ label, input }) => {
    return (
      <div className="field">
        <label>{label}</label>
        <textarea {...input} rows="2" />
      </div>
    );
  };

  onSubmit = ({ name, email, notes }) => {
    const { match, editClient } = this.props;
    const client = { name, mail: email, notes, id: match.params.id };
    editClient(client);
    this.setState({ isLoading: true });
  };

  render() {
    const { intl } = this.props;
    return (
      <Modal
        title={intl.formatMessage({ id: "clients.edit" })}
        content={this.renderContent()}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const client = state.clients.find(
    (client) => client.id === ownProps.match.params.id
  );
  return {
    initialValues: {
      name: client.name,
      email: client.mail,
      notes: client.notes,
    },
  };
};

export default connect(mapStateToProps, { editClient })(
  reduxForm({ form: "editForm", validate })(injectIntl(EditClient))
);
