export const WATCH_GET_CLIENTS = "WATCH_GET_CLIENTS";
export const SET_CLIENTS = "SET_CLIENTS";
export const ADD_CLIENT = "ADD_CLIENT";
export const EDIT_CLIENT = "EDIT_CLIENT";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const SELECTED_CLIENT = "SELECTED_CLIENT";

export const WATCH_GET_PROJECTS = "WATCH_GET_PROJECTS";
export const SET_PROJECTS = "SET_PROJECTS";
export const ADD_PROJECT = "ADD_PROJECT";
export const EDIT_PROJECT = "EDIT_PROJECT";
export const EDIT_USERSPROJECT = "EDIT_USERSPROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
