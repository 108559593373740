import React from "react";
import SearchBar from "./SearchBar";
import ClientList from "./Clients/ClientList";
import { ColorContext } from "../../Root";
import { getStyle } from "../../assets/styles/styles";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getClients, getProjects } from "./redux/actions";
import { FormattedMessage } from "react-intl";
import "../../assets/css/pages.css";

class Projects extends React.Component {
  static contextType = ColorContext;

  componentDidMount() {
    const { getClients, getProjects } = this.props;
    getClients("");
    getProjects();
  }

  onSearch = (searchTerm) => {
    searchTerm ? this.props.getClients(searchTerm) : this.props.getClients("");
  };

  render() {
    return (
      <div className="main-box">
        <div className="main-bar">
          <div className="left-content">
            <div className="ui large labels">
              <div className="ui basic label">
                <FormattedMessage id="clients.label" />
              </div>
              <Link
                to={{
                  pathname: "/clients/add-client",
                  state: { isModal: true },
                }}
                className="ui icon label"
                style={getStyle(this.context).button}
              >
                <i className="plus icon" />
                <FormattedMessage id="clients.addClient" />
              </Link>
            </div>
          </div>
          <div className="right-content">
            <SearchBar onSearch={this.onSearch} />
          </div>
        </div>
        <div className="main-content">
          <ClientList />
        </div>
      </div>
    );
  }
}

export default connect(null, { getClients, getProjects })(Projects);
