import React from "react";
import User from "./User";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import "../../../assets/css/tables.css";

const UserList = ({ users, currentUser }) => {
  if (!users) {
    return (
      <div className="ui basic placeholder segment">
        <div className="ui icon header">
          <i className="file outline icon" />
          <FormattedMessage id="dashboard.noUsers" />
        </div>
      </div>
    );
  } else if (users.length) {
    return (
      <table className="ui fixed table">
        <thead>
          <tr>
            <th className="wd20">
              <FormattedMessage id="common.name" />
            </th>
            <th className="wd20">
              <FormattedMessage id="common.type" />
            </th>
            <th className="wd40">Email</th>
            <th className="wd20"></th>
          </tr>
        </thead>
        <tbody>{renderUsers(users, currentUser)}</tbody>
      </table>
    );
  } else {
    return <div className="ui basic loading placeholder segment" />;
  }
};

const renderUsers = (users, currentUser) => {
  return users.map((user) => {
    if (user.organizationId === currentUser.organizationId) {
      return <User key={user.id} user={user} />;
    } else {
      return null;
    }
  });
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.user,
    users: state.users,
  };
};

export default connect(mapStateToProps)(UserList);
