import React from "react";
import Modal from "../../../Modal";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { validate, renderError } from "../../../utils/validations";
import { connect } from "react-redux";
import { addUser } from "./redux/actions";
import { ColorContext } from "../../../Root";
import { getStyle } from "../../../assets/styles/styles";
import { FormattedMessage, injectIntl } from "react-intl";

class AddUser extends React.Component {
  static contextType = ColorContext;
  state = { isLoading: false };

  renderContent = () => {
    const { intl, handleSubmit } = this.props;

    return (
      <form className="ui form error" onSubmit={handleSubmit(this.onSubmit)}>
        <Field
          name="name"
          label={intl.formatMessage({ id: "common.name" })}
          type="text"
          component={this.renderInput}
        />
        <Field
          name="email"
          label="Email:"
          type="text"
          component={this.renderInput}
        />
        <div className="required field">
          <label>
            <FormattedMessage id="common.type" />
          </label>
          <Field name="type" component="select">
            <option value="basic">
              {intl.formatMessage({ id: "dashboard.basicUser" })}
            </option>
            <option value="admin">
              {intl.formatMessage({ id: "dashboard.admin" })}
            </option>
          </Field>
        </div>

        <Link to="./" className="ui right floated button">
          <FormattedMessage id="common.cancel" />
        </Link>
        <button
          className={
            this.state.isLoading
              ? "ui right floated loading button"
              : `ui right floated button`
          }
          style={getStyle(this.context).button}
        >
          <FormattedMessage id="addUser.add" />
        </button>
      </form>
    );
  };

  renderInput = ({ meta, label, input }) => {
    return (
      <div className="required field">
        <label>{label}</label>
        <input {...input} />
        {renderError(meta)}
      </div>
    );
  };

  onSubmit = async ({ name, email, type }) => {
    const { organizationId } = this.props.user;
    const newUser = { name, email, type, organizationId };
    this.props.addUser(newUser);
    this.setState({ isLoading: true });
  };

  render() {
    const { intl } = this.props;
    return (
      <Modal
        title={intl.formatMessage({ id: "dashboard.addNew" })}
        content={this.renderContent()}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, { addUser })(
  reduxForm({ form: "addUser", validate })(injectIntl(AddUser))
);
