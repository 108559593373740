import React from "react";
import Modal from "../../Modal";
import { Field, reduxForm } from "redux-form";
import { validate, renderError } from "../../utils/validations";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";

class LoginForm extends React.Component {
  state = { isLoading: false };

  renderInput = ({ meta, label, input, type }) => {
    return (
      <div className="required field">
        <label>{label}</label>
        <input {...input} autoComplete="off" type={type} />
        {renderError(meta)}
      </div>
    );
  };

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
    this.setState({ isLoading: true });
  };

  renderTitle() {
    return (
      <h2 className="ui center aligned header">
        <img
          src={require("../../assets/images/logo.png")}
          alt="logo"
          className="ui small circular image"
        />
        <div className="ui content">Tracking Time</div>
      </h2>
    );
  }

  renderContent() {
    const { intl, handleSubmit } = this.props;
    return (
      <form
        className="ui form error"
        style={style.form}
        onSubmit={handleSubmit(this.onSubmit)}
      >
        <Field
          name="email"
          label="Email"
          type="text"
          component={this.renderInput}
        />
        <Field
          name="password"
          label={intl.formatMessage({ id: "login.password" })}
          component={this.renderInput}
          type="password"
        />
        <div className="inline fields" style={style.twofields}>
          <div className="inline fields">
            <Field
              name="rememberMe"
              type="checkbox"
              className="ui checkbox"
              component="input"
            />
            <label style={style.label}>
              <FormattedMessage id="login.rememberMe" />
            </label>
          </div>
          <div>
            <button
              className={
                this.state.isLoading ? "ui loading button" : "ui blue button"
              }
            >
              <FormattedMessage id="login.login" />
            </button>
            <Link className="ui button" to="/restore">
              {intl.formatMessage({ id: "login.forgot" })}
            </Link>
          </div>
        </div>
        <Link className="ui label" style={style.newOrg} to="/newOrganization">
          <FormattedMessage id="login.organization" />
        </Link>
      </form>
    );
  }
  render() {
    return (
      <Modal
        title={this.renderTitle()}
        content={this.renderContent()}
        isSignIn={true}
      />
    );
  }
}

const style = {
  form: {
    display: "flex",
    flexDirection: "column",
  },
  twofields: {
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    paddingLeft: "0.5em",
    fontWeight: "bold",
  },
  newOrg: {
    textAlign: "center",
  },
};

export default reduxForm({ form: "loginForm", validate })(
  injectIntl(LoginForm)
);
