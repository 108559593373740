import React from "react";
import Modal from "../../../Modal";
import { Link } from "react-router-dom";
import { addProject } from "../redux/actions";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { ColorContext } from "../../../Root";
import { getStyle } from "../../../assets/styles/styles";
import { validate, renderError } from "../../../utils/validations";
import { injectIntl, FormattedMessage } from "react-intl";

class AddProject extends React.Component {
  state = { isLoading: false };
  static contextType = ColorContext;

  renderContent = () => {
    const { handleSubmit, intl } = this.props;

    return (
      <form className="ui form error" onSubmit={handleSubmit(this.onSubmit)}>
        <Field
          name="projectName"
          label={intl.formatMessage({ id: "addProject.name" })}
          component={this.renderInput}
          comp="1"
        />

        <Field
          name="description"
          label={intl.formatMessage({ id: "common.description" })}
          component={this.renderInput}
          comp="2"
        />

        <Link to="/clients" className="ui right floated button">
          <FormattedMessage id="common.cancel" />
        </Link>
        <button
          className={
            this.state.isLoading
              ? "ui right floated loading button"
              : "ui right floated button"
          }
          style={getStyle(this.context).button}
        >
          <FormattedMessage id="common.add" />
        </button>
      </form>
    );
  };

  renderInput = ({ meta, label, input, comp }) => {
    return (
      <div className="field">
        <label>{label}</label>
        {comp === "1" ? (
          <input {...input} type="text" />
        ) : (
          <textarea {...input} rows="2" />
        )}
        {renderError(meta)}
      </div>
    );
  };

  onSubmit = async ({ projectName, description }) => {
    const { clients, match, addProject } = this.props;
    let client = clients.find((client) => match.params.id === client.id);
    let project = { projectName, description };
    addProject(client, project);
    this.setState({ isLoading: true });
  };

  render() {
    const { intl } = this.props;
    return (
      <Modal
        title={intl.formatMessage({ id: "addProject.title" })}
        content={this.renderContent()}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.clients,
  };
};

export default connect(mapStateToProps, { addProject })(
  reduxForm({ form: "addProject", validate })(injectIntl(AddProject))
);
