import React from "react";
import Modal from "../../../Modal";
import { ColorContext } from "../../../Root";
import { getStyle } from "../../../assets/styles/styles";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { renderError, validate } from "../../../utils/validations";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { editUser } from "./redux/actions";

class EditUser extends React.Component {
  static contextType = ColorContext;
  state = { isLoading: false };
  renderContent = () => {
    const { handleSubmit, intl, pristine } = this.props;
    return (
      <form className="ui form error" onSubmit={handleSubmit(this.onSubmit)}>
        <Field
          name="displayName"
          label={intl.formatMessage({ id: "common.name" })}
          component={this.renderInput}
        />
        <Link to="/dashboard" className="ui right floated button">
          <FormattedMessage id="common.cancel" />
        </Link>
        <button
          className={
            this.state.isLoading
              ? "ui right floated loading button"
              : `ui right floated button`
          }
          style={getStyle(this.context).button}
          disabled={pristine ? true : false}
        >
          <FormattedMessage id="common.save" />
        </button>
      </form>
    );
  };

  renderInput = ({ meta, label, input }) => {
    return (
      <div className="field">
        <label>{label}</label>
        <input {...input} type="text" />
        {renderError(meta)}
      </div>
    );
  };

  onSubmit = ({ displayName }) => {
    const user = { displayName, id: this.props.match.params.id };
    this.props.editUser(user);
    this.setState({ isLoading: true });
  };

  render() {
    const { intl } = this.props;
    return (
      <Modal
        title={intl.formatMessage({ id: "dashboard.editTitle" })}
        content={this.renderContent()}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const user = state.users.find((user) => user.id === ownProps.match.params.id);
  return {
    initialValues: {
      displayName: user.displayName,
    },
  };
};

export default connect(mapStateToProps, { editUser })(
  reduxForm({ form: "editUser", validate })(injectIntl(EditUser))
);
