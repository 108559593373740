import { SET_USERS } from "./types";

const initialState = {
  id: "",
  displayName: "",
  isAdmin: "",
  organizationId: "",
  email: "",
};

const usersReducer = (state = [initialState], action) => {
  switch (action.type) {
    case SET_USERS:
      let users = [];
      action.query.forEach((doc) => {
        users = [
          ...users,
          {
            id: doc.id,
            displayName: doc.data().displayName,
            isAdmin: doc.data().isAdmin,
            organizationId: doc.data().organizationId,
            email: doc.data().email,
          },
        ];
      });
      if (action.searchTerm !== "") {
        let usersFiltered = [];
        users.forEach((user) => {
          if (
            user.displayName
              .toLowerCase()
              .includes(action.searchTerm.toLowerCase())
          ) {
            usersFiltered = [...usersFiltered, { ...user }];
          }
        });
        if (usersFiltered.length) {
          return usersFiltered;
        } else {
          return false;
        }
      }
      return users;

    default:
      return state;
  }
};

export default usersReducer;
