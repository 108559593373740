import { SET_PROJECTS } from "./types";

const projectsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_PROJECTS:
      let projects = [];
      action.query.forEach((doc) => {
        projects = [
          ...projects,
          {
            id: doc.id,
            clientId: doc.data().clientId,
            mail: doc.data().mail,
            desc: doc.data().desc,
            name: doc.data().name,
            usersId: doc.data().usersId,
            hide: false,
          },
        ];
      });
      return projects;

    default:
      return state;
  }
};

export default projectsReducer;
