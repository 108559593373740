import React from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import "moment/locale/es";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: "60px",
  },
  view: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  logo: {
    height: "40px",
  },
  titleContent: {
    width: "300px",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px",
    borderBottomColor: "#eeeeee",
    borderBottomStyle: "solid",
    marginBottom: "10px",
    marginTop: "10px",
    paddingBottom: "10px",
  },
  firstLine: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "16px",
    lineHeight: "1.6",
    color: "#121212",
  },

  line: {
    display: "flex",
    flexDirection: "row",
  },
  subtitle: {
    fontSize: "14px",
    lineHeight: "1.4",
    color: "#808080",
  },
  subtitleContent: {
    fontSize: "14px",
    lineHeight: "1.4",
    color: "#121212",
  },
  withMargin: {
    marginTop: "20px",
    marginBottom: "10px",
    paddingBottom: "10px",
    borderBottom: "2px",
    borderBottomColor: "#dde8f5",
    borderBottomStyle: "solid",
  },
  text: {
    fontSize: "11px",
    lineHeight: "1.3",
    color: "#444444",
  },
  subText: {
    fontSize: "11px",
    lineHeight: "1.3",
    color: "#808080",
  },
});

class RenderPdf extends React.Component {
  renderTimeRange = () => {
    const { timeRange, locale } = this.props.data;
    timeRange.today.locale(locale);
    const month =
      timeRange.today.format("MMMM").charAt(0).toUpperCase() +
      timeRange.today.format("MMMM").slice(1);
    if (timeRange.option === "week") {
      return `${timeRange.today
        .startOf("week")
        .format("DD")} - ${timeRange.today.endOf("week").format("DD MMM")}`;
    } else if (timeRange.option === "month") {
      return month;
    } else {
      return timeRange.today.format("L");
    }
  };

  renderItems = (reports) => {
    return reports.map((report) => {
      return (
        <View style={styles.item} key={report.id}>
          <View style={styles.firstLine}>
            <View>
              <Text style={styles.text}>{report.date}</Text>
            </View>
            <View>
              <Text style={styles.text}>{report.time}</Text>
            </View>
          </View>
          <View style={styles.line}>
            <Text style={styles.subText}>Project: </Text>
            <Text style={styles.text}>{report.projectName}</Text>
          </View>
          <Text style={styles.text}>{report.description}</Text>
        </View>
      );
    });
  };

  render() {
    const { reports, hours, minutes, user } = this.props.data;
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.view}>
            <View style={styles.titleContent}>
              <Text style={styles.title}>Detailed Report</Text>
              <View style={styles.line}>
                <Text style={styles.subtitle}>{`User: `}</Text>
                <Text style={styles.subtitleContent}>{user.displayName}</Text>
              </View>
              <View style={styles.line}>
                <Text style={styles.subtitle}>{`From: `}</Text>
                <Text style={styles.subtitleContent}>
                  {this.renderTimeRange()}
                </Text>
              </View>
              <View style={styles.line}>
                <Text style={styles.subtitle}>{`Total: `}</Text>
                <Text style={styles.subtitleContent}>
                  {hours}hs {minutes}min
                </Text>
              </View>
            </View>
            <View>
              <Image
                style={styles.logo}
                src={require("../../assets/images/logo.png")}
              />
            </View>
          </View>
          <View>
            <Text style={[styles.subtitle, styles.withMargin]}>Summary</Text>
          </View>
          <View>{this.renderItems(reports)}</View>
        </Page>
      </Document>
    );
  }
}

export default RenderPdf;
