import { SELECTED_CLIENT } from "./types";

const selectedClientReducer = (state = {}, { type, client }) => {
  switch (type) {
    case SELECTED_CLIENT:
      return client;
    default:
      return state;
  }
};

export default selectedClientReducer;
