import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ColorContext } from "../../../Root";
import { getStyle } from "../../../assets/styles/styles";

import { FormattedMessage } from "react-intl";

const User = ({ user }) => {
  const context = useContext(ColorContext);
  return (
    <tr>
      <td>{user.displayName}</td>
      <td>
        {user.isAdmin ? (
          <FormattedMessage id="dashboard.admin" />
        ) : (
          <Link
            to={{
              pathname: `/dashboard/delete/${user.id}`,
              state: { isModal: true },
            }}
            className="ui red icon label"
          >
            <i className="x icon" />
            <FormattedMessage id="dashboard.basicUser" />
          </Link>
        )}
      </td>
      <td>{user.email}</td>
      <td className="right aligned">
        <Link
          to={{
            pathname: `/dashboard/edit/${user.id}`,
            state: { isModal: true },
          }}
          className={`ui icon label `}
          style={getStyle(context).button}
        >
          <i className="edit outline icon" />
          <FormattedMessage id="common.edit" />
        </Link>
        <Link
          to={`/dashboard/report/${user.id}`}
          className="ui icon label"
          style={getStyle(context).button}
        >
          <i className="chart line icon" />
          <FormattedMessage id="common.report" />
        </Link>
      </td>
    </tr>
  );
};

export default User;
