import React from "react";
import Select from "react-select";
import Modal from "../../../Modal";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { editUsersProject } from "../redux/actions";
import { ColorContext } from "../../../Root";
import { getStyle } from "../../../assets/styles/styles";
import { FormattedMessage } from "react-intl";

class ProjectUsers extends React.Component {
  static contextType = ColorContext;
  state = { usersId: [], pristine: true };

  renderActions = () => {
    return (
      <React.Fragment>
        <button
          className="ui button"
          style={getStyle(this.context).button}
          onClick={this.handleSubmit}
          disabled={this.state.pristine ? true : false}
        >
          <FormattedMessage id="common.save" />
        </button>
        <Link to="/clients/" className="ui button">
          <FormattedMessage id="common.cancel" />
        </Link>
      </React.Fragment>
    );
  };

  handleSubmit = () => {
    const { editUsersProject, project } = this.props;
    editUsersProject(this.state, project.id);
  };

  handleChange = (inputValues) => {
    let users = [];
    if (inputValues) {
      inputValues.forEach((input) => users.push(input.value));
      this.setState({ usersId: users, pristine: false });
    } else {
      this.setState({ usersId: [], pristine: false });
    }
  };

  getDefaults = (defaults = []) => {
    const { project, users } = this.props;
    project.usersId.forEach((projectUser) =>
      users.forEach((user) => {
        if (user.id === projectUser) {
          defaults.push({ value: user.id, label: user.displayName });
        }
      })
    );
    return defaults;
  };

  getOptions = (options = []) => {
    const { users } = this.props;
    users.forEach((user) => {
      if (user.organizationId === this.props.user.organizationId) {
        options.push({ value: user.id, label: user.displayName });
      }
    });
    return options;
  };

  renderContent = () => {
    return (
      <Select
        isMulti
        defaultValue={this.getDefaults()}
        options={this.getOptions()}
        onChange={this.handleChange}
      />
    );
  };

  render() {
    return (
      <Modal
        title={this.props.project.name}
        content={this.renderContent()}
        actions={this.renderActions()}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    users: state.users,
    project: state.projects.find(
      (project) => project.id === ownProps.match.params.id
    ),
  };
};
export default connect(mapStateToProps, { editUsersProject })(ProjectUsers);
