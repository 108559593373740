import React from "react";
import Modal from "../../../Modal";
import { ColorContext } from "../../../Root";
import { getStyle } from "../../../assets/styles/styles";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { renderError, validate } from "../../../utils/validations";
import { connect } from "react-redux";
import { editProject } from "../redux/actions";
import { injectIntl, FormattedMessage } from "react-intl";

class EditProject extends React.Component {
  state = { isLoading: false };
  static contextType = ColorContext;
  renderContent = () => {
    const { handleSubmit, intl, pristine } = this.props;

    return (
      <form className="ui form error" onSubmit={handleSubmit(this.onSubmit)}>
        <Field
          name="projectName"
          label={intl.formatMessage({ id: "common.name" })}
          component={this.renderInput}
        />

        <Field
          name="description"
          label={intl.formatMessage({ id: "common.description" })}
          component={this.renderTextArea}
        />
        <Link to="/clients" className="ui right floated button">
          <FormattedMessage id="common.cancel" />
        </Link>
        <button
          className={
            this.state.isLoading
              ? "ui right floated loading button"
              : "ui right floated button"
          }
          style={getStyle(this.context).button}
          disabled={pristine ? true : false}
        >
          <FormattedMessage id="common.save" />
        </button>
      </form>
    );
  };

  renderInput = ({ meta, label, input }) => {
    return (
      <div className="field">
        <label>{label}</label>
        <input {...input} type="text" />
        {renderError(meta)}
      </div>
    );
  };

  renderTextArea = ({ label, input }) => {
    return (
      <div className="field">
        <label>{label}</label>
        <textarea {...input} rows="2" />
      </div>
    );
  };

  onSubmit = ({ projectName, description }) => {
    const { match, editProject } = this.props;
    const project = {
      projectName,
      description,
      id: match.params.id,
    };
    editProject(project);
    this.setState({ isLoading: true });
  };

  render() {
    const { intl } = this.props;
    return (
      <Modal
        title={intl.formatMessage({ id: "editProject.title" })}
        content={this.renderContent()}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const project = state.projects.find(
    (project) => project.id === ownProps.match.params.id
  );
  return {
    initialValues: {
      projectName: project.name,
      description: project.desc,
    },
  };
};

export default connect(mapStateToProps, { editProject })(
  reduxForm({ form: "editProject", validate })(injectIntl(EditProject))
);
