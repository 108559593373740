import React from "react";
import Modal from "../../Modal";
import moment from "moment";
import { ColorContext } from "../../Root";
import { getStyle } from "../../assets/styles/styles";
import { validate } from "../../utils/validations";
import { Link } from "react-router-dom";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { getProjects } from "../../components/Projects/redux/actions";
import { getReports, editReport } from "./redux/actions";

class EditReport extends React.Component {
  state = { isLoading: false };
  static contextType = ColorContext;

  componentDidMount() {
    const { getProjects, getReports, user } = this.props;
    getReports(user);
    getProjects();
  }

  renderContent = () => {
    const { handleSubmit, intl, match } = this.props;
    return (
      <form className="ui form" onSubmit={handleSubmit(this.onSubmit)}>
        <div className="required field">
          <label>{intl.formatMessage({ id: "common.project" })}</label>
          <Field name="project" type="select" component="select">
            {this.renderOptions()}
          </Field>
        </div>

        <div className="two fields">
          <div className="field">
            <label>
              <FormattedMessage id="addReport.hours" />
            </label>
            <Field name="hours" component={this.renderField} />
          </div>
          <div className="field">
            <label>
              <FormattedMessage id="addReport.minutes" />
            </label>
            <Field name="minutes" component={this.renderField} />
          </div>
        </div>

        <div className="required field">
          <label>{intl.formatMessage({ id: "common.description" })}</label>
          <Field name="description" component="textarea" />
        </div>

        <Link
          to={`/dashboard/report/${match.params.user}`}
          className="ui right floated button"
        >
          <FormattedMessage id="common.cancel" />
        </Link>
        <button
          className={
            this.state.isLoading
              ? "ui right floated loading  button"
              : `ui right floated  button`
          }
          style={getStyle(this.context).button}
        >
          <FormattedMessage id="common.save" />
        </button>
        <br />
      </form>
    );
  };

  renderOptions = () => {
    const { projects, intl } = this.props;
    return (
      <React.Fragment>
        {projects.length ? null : (
          <option value="">
            {intl.formatMessage({ id: "addReport.noProjects" })}
          </option>
        )}
        {projects.map((project) => (
          <option value={project.id} key={project.id}>
            {project.name}
          </option>
        ))}
      </React.Fragment>
    );
  };

  renderField = ({ input, meta: { touched, error } }) => (
    <div>
      <input {...input} type="number" />
      {touched && error && <span>{error}</span>}
    </div>
  );

  onSubmit = ({ description, project, hours, minutes }) => {
    const { intl } = this.props;
    if (!description || !project) {
      !description
        ? window.alert(intl.formatMessage({ id: "errors.noDescription" }))
        : window.alert(intl.formatMessage({ id: "errors.noProject" }));
      throw new SubmissionError("Error");
    } else {
      const { projects, editReport, selectedReport, user } = this.props;
      const time = `${hours < 10 ? "0" + hours : hours}:${
        minutes < 10 ? "0" + minutes : minutes
      }`;
      const selectedProject = projects.find(
        (element) => element.id === project
      );
      const report = {
        id: selectedReport.id,
        description,
        projectId: selectedProject.id,
        projectName: selectedProject.name,
        time,
        user,
      };
      editReport(report);
      this.setState({ isLoading: true });
    }
  };

  render() {
    const { intl } = this.props;
    return (
      <Modal
        title={intl.formatMessage({ id: "editReport.title" })}
        content={this.renderContent()}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const user = ownProps.match.params.user;
  let report = {};
  const projectsForThisUser = [];
  state.projects.forEach((project) => {
    if (project.usersId.find((userId) => userId === user)) {
      projectsForThisUser.push(project);
    }
  });
  if (state.reports.length) {
    report = state.reports.find(
      (report) => report.id === ownProps.match.params.report
    );
    report.hours = moment(report.time, "HH:mm").get("hours");
    report.minutes = moment(report.time, "HH:mm").get("minutes");
  } else {
    report = { projectId: "", description: "", hours: 0, minutes: 0 };
  }
  return {
    user: user,
    projects: projectsForThisUser,
    selectedReport: report,
    initialValues: {
      hours: report.hours,
      minutes: report.minutes,
      project: report.projectId,
      description: report.description,
    },
  };
};

export default connect(mapStateToProps, {
  getProjects,
  editReport,
  getReports,
})(reduxForm({ form: "editReportForm", validate })(injectIntl(EditReport)));
