import React from "react";
import store from "./store/createStore";

import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import { translations, setLanguage } from "./utils/translations";
import { Router } from "react-router-dom";
import history from "./history";
import Root from "./Root";
import Routes from "./Routes";
import Header from "./components/Header";

const App = () => {
  const currentLocale = setLanguage();
  const messages = translations[currentLocale];

  return (
    <IntlProvider
      locale={currentLocale}
      key={currentLocale}
      messages={messages}
    >
      <Provider store={store}>
        <div className="ui container">
          <Root>
            <Router history={history}>
              <Header />
              <div className="ui segment">
                <Routes />
              </div>
            </Router>
          </Root>
        </div>
      </Provider>
    </IntlProvider>
  );
};

export default App;
