import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";

class GuardRoute extends React.Component {
  render() {
    const { user, type, ...rest } = this.props;

    if (!user.isLogged && type === "private") {
      return <Redirect to="/login" />;
    } else if (user.isLogged && type === "public") {
      return <Redirect to="/dashboard" />;
    }

    return <Route {...rest} />;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(GuardRoute);
