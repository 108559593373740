import React from "react";
import ReactDOM from "react-dom";

const Modal = (props) => {
  const { title, content, actions, isSignIn } = props;
  return ReactDOM.createPortal(
    <div
      className="ui dimmer modals visible active"
      style={isSignIn ? style.login : style.empty}
    >
      <div className="ui standard modal visible active" style={style.flex}>
        <div className="title" style={style.title}>
          {title}
        </div>
        <div className="content">{content}</div>
        <div className="actions">{actions}</div>
      </div>
    </div>,
    document.querySelector("#modal")
  );
};

const style = {
  flex: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    alignSelf: "center",
    textAlign: "center",
    background: "#f9fafb",
    fontSize: "1.5em",
    height: "3em",
    width: "100%",
    paddingTop: "0.75em",
    paddingBottom: "0.75em",
    borderBottom: "1px solid rgba(34,36,38,.15)",
  },
  login: {
    backgroundColor: "black",
  },
  empty: {},
};

export default Modal;
