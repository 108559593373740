import React from "react";
import DataItem from "./DataItem";
import moment from "moment";
import RenderPdf from "./RenderPDF";
import { connect } from "react-redux";
import { ColorContext } from "../../Root";
import { getStyle } from "../../assets/styles/styles";
import { FormattedMessage } from "react-intl";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { dateFormat } from "../../utils/dateFormat";
import "../../assets/css/tables.css";

class DataReport extends React.Component {
  static contextType = ColorContext;

  renderItems = (reports) => {
    return reports.map((report) => {
      return (
        <DataItem key={report.id} report={report} user={this.props.user.id} />
      );
    });
  };

  renderHeader = () => {
    return (
      <thead>
        <tr>
          <th className="wd10">
            <FormattedMessage id="report.date" />
          </th>
          <th className="wd20">
            <FormattedMessage id="common.project" />
          </th>
          <th className="wd40">
            <FormattedMessage id="common.description" />
          </th>
          <th className="wd20"></th>
          <th className="wd10">
            <FormattedMessage id="report.hours" />
          </th>
        </tr>
      </thead>
    );
  };

  renderFooter() {
    const { reports, hours, minutes, user, timeRange, locale } = this.props;
    const data = { reports, hours, minutes, user, timeRange, locale };
    return (
      <tfoot>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th>{reports.length ? this.renderPdfLink(data) : null}</th>
          <th className="right aligned">
            <strong>
              <FormattedMessage id="report.hours" />:
            </strong>
            {hours < 10 ? ` 0${hours}` : ` ${hours}`} <br />
            <strong>
              <FormattedMessage id="report.minutes" />:
            </strong>
            {minutes < 10 ? ` 0${minutes}` : ` ${minutes}`} <br />
          </th>
        </tr>
      </tfoot>
    );
  }

  renderPdfLink = (data) => (
    <PDFDownloadLink
      document={<RenderPdf data={data} />}
      fileName="report.pdf"
      className={`ui right floated mini icon button`}
      style={getStyle(this.context).button}
    >
      {({ loading }) =>
        loading ? (
          <i className="ui cloud download icon" />
        ) : (
          <i className="ui cloud download icon" />
        )
      }
    </PDFDownloadLink>
  );

  render() {
    return (
      <table className={`ui ${this.context} fixed striped table`}>
        {this.renderHeader()}
        <tbody>{this.renderItems(this.props.reports)}</tbody>
        {this.renderFooter()}
      </table>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { option, today } = ownProps.timeRange;
  let matchReports = [];
  let totalHours = moment.duration(0, "hours");
  if (option === "day") {
    state.reports.forEach((report) => {
      if (moment(report.date, dateFormat).format("D") === today.format("D")) {
        matchReports.push(report);
        totalHours.add(report.time, "hours");
      }
    });
  } else if (option === "week" || option === "month") {
    state.reports.forEach((report) => {
      let date = moment(report.date, dateFormat);
      let startOf = moment(today, dateFormat).startOf(option);
      let endOf = moment(today, dateFormat).endOf(option);
      if (date.isBetween(startOf, endOf, undefined, "[]")) {
        matchReports.push(report);
        totalHours.add(report.time, "hours");
      }
    });
  }
  return {
    reports: matchReports,
    hours: totalHours.get("days") * 24 + totalHours.get("hours"),
    minutes: totalHours.get("minutes"),
  };
};

export default connect(mapStateToProps)(DataReport);
