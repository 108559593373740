import {
  WATCH_GET_REPORTS,
  SET_REPORTS,
  CLEAR_REPORTS_DATA,
  ADD_REPORT,
  DELETE_REPORT,
  EDIT_REPORT,
} from "./types";

export const getReports = (userId) => ({
  type: WATCH_GET_REPORTS,
  userId,
});

export const setReports = (query) => ({
  type: SET_REPORTS,
  query,
});

export const addReport = (report) => ({
  type: ADD_REPORT,
  report,
});

export const deleteReport = (report) => ({
  type: DELETE_REPORT,
  report,
});

export const editReport = (report) => ({
  type: EDIT_REPORT,
  report,
});

export const clearReportsData = () => ({
  type: CLEAR_REPORTS_DATA,
});
