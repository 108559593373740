import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ColorContext } from "../../../Root";
import { getStyle } from "../../../assets/styles/styles";

import { FormattedMessage } from "react-intl";

const Project = ({ project }) => {
  const context = useContext(ColorContext);
  return (
    <tr>
      <td>{project.name}</td>
      <td>{project.desc}</td>
      <td>{project.usersId ? project.usersId.length : 0}</td>

      <td className="right aligned">
        <Link
          to={{
            pathname: `/clients/project/edit-project/${project.id}`,
            state: { isModal: true },
          }}
          className="ui icon label"
          style={getStyle(context).button}
        >
          <i className="edit outline icon" />
          <FormattedMessage id="common.edit" />
        </Link>
        <Link
          to={{
            pathname: `/clients/project/users/${project.id}`,
            state: { isModal: true },
          }}
          className="ui icon label"
          style={getStyle(context).button}
        >
          <i className="users icon" />
          <FormattedMessage id="common.users" />
        </Link>
        <Link
          to={{
            pathname: `/clients/project/delete-project/${project.id}`,
            state: { isModal: true },
          }}
          className="ui red icon label"
        >
          <i className="trash icon" />
          <FormattedMessage id="common.delete" />
        </Link>
      </td>
    </tr>
  );
};

export default Project;
