import { SET_CLIENTS } from "./types";

const clientsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_CLIENTS:
      let clients = [];
      action.query.forEach((doc) => {
        clients = [
          ...clients,
          {
            id: doc.id,
            mail: doc.data().mail,
            name: doc.data().name,
            notes: doc.data().notes,
            organizationId: doc.data().organizationId,
            projects: doc.data().projects,
            hide: false,
          },
        ];
      });
      if (action.searchTerm !== "") {
        let clientsFiltered = [];
        clients.forEach((client) => {
          if (
            client.name.toLowerCase().includes(action.searchTerm.toLowerCase())
          ) {
            clientsFiltered = [...clientsFiltered, { ...client }];
          }
        });
        if (clientsFiltered.length) {
          return clientsFiltered;
        } else {
          return false;
        }
      }
      return clients;

    default:
      return state;
  }
};

export default clientsReducer;
