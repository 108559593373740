import React from "react";
import moment from "moment";
import DataReport from "./DataReport";
import { injectIntl } from "react-intl";
import { ColorContext } from "../../Root";
import { getStyle } from "../../assets/styles/styles";

import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { dateFormat } from "../../utils/dateFormat";
import "moment/locale/es";
import "../../assets/css/pages.css";

class TimeControl extends React.Component {
  state = { today: moment(), option: "day" };
  static contextType = ColorContext;

  increase = () => {
    this.setState({
      today: moment(this.state.today).add(1, this.state.option),
    });
  };

  decrease = () => {
    this.setState({
      today: moment(this.state.today).subtract(1, this.state.option),
    });
  };

  renderLabel = () => {
    const { today, option } = this.state;
    const locale = this.props.intl.locale.slice(0, 2);
    today.locale(locale);
    const month =
      today.format("MMMM").charAt(0).toUpperCase() +
      today.format("MMMM").slice(1);
    if (option === "week") {
      return `${today.startOf("week").format("DD")} - ${today
        .endOf("week")
        .format("DD MMM")}`;
    } else if (option === "month") {
      return month;
    } else {
      return today.format("L");
    }
  };

  getSelectValue = () => {
    let e = document.getElementById("select");
    let option = e.options[e.selectedIndex].value;
    this.setState({ option: option, today: moment() });
  };

  renderButtons = () => {
    if (this.state.today >= moment().subtract(1, "day")) {
      return "ui disabled right attached mini button";
    } else {
      return "ui right attached mini button";
    }
  };

  renderDataReport = () => {
    const { reports, user, intl } = this.props;
    if (!reports) {
      return (
        <div>
          <div className="ui basic placeholder segment">
            <div className="ui icon header">
              <i className="file outline icon" />
              <FormattedMessage id="report.noUsers" />
            </div>
          </div>
        </div>
      );
    }
    if (reports.length) {
      return (
        <DataReport
          timeRange={this.state}
          user={user}
          locale={intl.locale.slice(0, 2)}
        />
      );
    } else {
      return <div className="ui basic loading placeholder segment " />;
    }
  };

  render() {
    const { intl, user } = this.props;
    return (
      <div>
        <div className="main-box">
          <div className="main-bar">
            <div className="left-content">
              <button
                className="ui left attached mini button"
                onClick={this.decrease}
              >
                <i className="angle left icon" />
              </button>
              <button className={this.renderButtons()} onClick={this.increase}>
                <i className="angle right icon" />
              </button>
            </div>
            <div className="center-content">
              <div className="ui large basic label">{this.renderLabel()}</div>
            </div>
            <div className="right-content">
              <Link
                className="ui tiny icon button"
                style={getStyle(this.context).button}
                to={{
                  pathname: `/dashboard/report/add/${user.id}/${moment(
                    this.state.today
                  ).format(dateFormat)}`,
                  state: { isModal: true },
                }}
              >
                <i className="plus icon" />
              </Link>
              <select
                id="select"
                className="ui basic label"
                onChange={this.getSelectValue}
              >
                <option value="day">
                  {intl.formatMessage({ id: "report.day" })}
                </option>
                <option value="week">
                  {intl.formatMessage({ id: "report.week" })}
                </option>
                <option value="month">
                  {intl.formatMessage({ id: "report.month" })}
                </option>
              </select>
            </div>
          </div>
        </div>

        {this.renderDataReport()}
      </div>
    );
  }
}

export default injectIntl(TimeControl);
