import { takeLatest, put, all, fork } from "redux-saga/effects";
import {
  WATCH_GET_CLIENTS,
  ADD_CLIENT,
  EDIT_CLIENT,
  DELETE_CLIENT,
} from "./types";
import { setClients } from "./actions";
import { db, clients, projects } from "../../../services/firebase";
import history from "../../../history";

function* settingClients({ searchTerm }) {
  try {
    const query = yield db.collection(clients).where("hide", "==", false).get();
    yield put(setClients(query, searchTerm));
  } catch (error) {
    yield alert(error);
  }
}

function* addingClient({ client }) {
  try {
    if (!client.notes) {
      client.notes = "";
    }
    yield db.collection(clients).add({
      name: client.name,
      mail: client.mail,
      projects: client.projects,
      notes: client.notes,
      organizationId: client.organizationId,
      hide: false,
    });
    history.push("/clients");
    yield put({ type: WATCH_GET_CLIENTS, searchTerm: "" });
  } catch (error) {
    yield alert(error);
    history.push("/clients");
  }
}

function* edditingClient({ client }) {
  try {
    yield db
      .collection(clients)
      .doc(client.id)
      .update({ name: client.name, mail: client.mail, notes: client.notes });
    history.push("/clients");
    yield put({ type: WATCH_GET_CLIENTS, searchTerm: "" });
  } catch (error) {
    yield alert(error);
    history.push("/clients");
  }
}

function* delettingClient({ client }) {
  try {
    yield db.collection(clients).doc(client).update({ hide: true });
    yield db
      .collection(projects)
      .where("clientId", "==", client)
      .get()
      .then((snap) =>
        snap.forEach((project) =>
          db.collection(projects).doc(project.id).update({ hide: true })
        )
      );
    history.push("/clients");
    yield put({ type: WATCH_GET_CLIENTS, searchTerm: "" });
  } catch (error) {
    console.log(error);
  }
}

function* watchClients() {
  yield takeLatest(WATCH_GET_CLIENTS, settingClients);
}

function* addClient() {
  yield takeLatest(ADD_CLIENT, addingClient);
}

function* editClient() {
  yield takeLatest(EDIT_CLIENT, edditingClient);
}

function* deleteClient() {
  yield takeLatest(DELETE_CLIENT, delettingClient);
}

function* ClientsSaga() {
  yield all([
    fork(watchClients),
    fork(addClient),
    fork(editClient),
    fork(deleteClient),
  ]);
}

export default ClientsSaga;
