import {
  LOG_IN,
  LOG_OUT,
  ADD_NEW,
  GET_USER_DATA,
  CLEAR_USER_DATA,
  DELETE_ACCOUNT,
  CHANGE_PASSWORD,
  RESTORE_PASSWORD,
  SET_USER_DATA,
} from "./types";

export const logIn = (formValues) => ({
  type: LOG_IN,
  formValues,
});

export const addNew = (newOrg) => ({
  type: ADD_NEW,
  newOrg,
});

export const getUserData = (user) => ({
  type: GET_USER_DATA,
  user,
});

export const setUserData = (user) => ({
  type: SET_USER_DATA,
  user,
});

export const logOut = () => ({
  type: LOG_OUT,
});

export const clearUserData = () => ({
  type: CLEAR_USER_DATA,
});

export const deleteAccount = (password) => ({
  type: DELETE_ACCOUNT,
  password,
});

export const changePassword = (passwords) => ({
  type: CHANGE_PASSWORD,
  passwords,
});

export const restorePassword = (email) => ({
  type: RESTORE_PASSWORD,
  email,
});
