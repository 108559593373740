import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import usersReducer from "../components/Dashboard/Users/redux/UsersReducer";
import loginReducer from "../components/login/redux/loginReducer";
import clientsReducer from "../components/Projects/redux/ClientsReducer";
import projectsReducer from "../components/Projects/redux/ProjectsReducer";
import reportsReducer from "../components/Report/redux/reportReducer";
import selectedClientReducer from "../components/Projects/redux/selectedClientReducer";

export default combineReducers({
  user: loginReducer,
  users: usersReducer,
  clients: clientsReducer,
  projects: projectsReducer,
  reports: reportsReducer,
  selectedClient: selectedClientReducer,
  form: formReducer,
});
