import React from "react";
import { Field, reduxForm } from "redux-form";
import { injectIntl } from "react-intl";
import "../../assets/css/pages.css";

const styles = {
  searchBar: {
    display: "inline-grid",
  },
};

class SearchBar extends React.Component {
  state = { searchTerm: "" };

  onSubmit = async ({ searchTerm }) => {
    await this.setState({ searchTerm: searchTerm });
    this.props.onSearch(this.state.searchTerm);
  };

  render() {
    const { handleSubmit, intl } = this.props;
    return (
      <form
        className="ui tiny form"
        style={styles.searchBar}
        onKeyUp={handleSubmit(this.onSubmit)}
      >
        <div className="field">
          <div className="ui left icon input">
            <Field
              name="searchTerm"
              type="text"
              placeholder={intl.formatMessage({ id: "dashboard.searchBar" })}
              component="input"
            />
            <i className="users icon"></i>
          </div>
        </div>
      </form>
    );
  }
}

export default injectIntl(reduxForm({ form: "searchUsers" })(SearchBar));
