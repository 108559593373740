import React from "react";
import { Field, reduxForm } from "redux-form";
import { injectIntl } from "react-intl";

class SearchBar extends React.Component {
  state = { searchTerm: "" };

  onSubmit = async ({ searchTerm }) => {
    await this.setState({ searchTerm: searchTerm });
    this.props.onSearch(this.state.searchTerm);
  };

  render() {
    const { handleSubmit, intl } = this.props;

    return (
      <form className="ui tiny form" onKeyUp={handleSubmit(this.onSubmit)}>
        <div className="field">
          <div className="ui left icon input">
            <Field
              name="searchTerm"
              type="text"
              placeholder={intl.formatMessage({
                id: "clients.search",
              })}
              component="input"
            />
            <i className="search icon"></i>
          </div>
        </div>
      </form>
    );
  }
}

export default reduxForm({ form: "searchClients" })(injectIntl(SearchBar));
