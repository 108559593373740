import React from "react";
import { connect } from "react-redux";

export const ColorContext = React.createContext({
  backgroundColor: "blue",
  color: "white",
});

const Root = (props) => {
  const { children, user } = props;

  return (
    <ColorContext.Provider
      value={{ backgroundColor: user.color, color: user.textColor }}
    >
      {children}
    </ColorContext.Provider>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(Root);
