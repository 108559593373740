import React from "react";
import Modal from "../../../Modal";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { deleteClient } from "../redux/actions";
import { FormattedMessage, injectIntl } from "react-intl";

class DeleteClient extends React.Component {
  deleteClient = () => {
    const { match, deleteClient } = this.props;
    const client = match.params.id;
    deleteClient(client);
  };

  renderActions = () => {
    return (
      <React.Fragment>
        <button className="ui red button" onClick={() => this.deleteClient()}>
          <FormattedMessage id="common.delete" />
        </button>
        <Link to="/clients" className="ui button">
          <FormattedMessage id="common.cancel" />
        </Link>
      </React.Fragment>
    );
  };

  render() {
    const { intl } = this.props;
    return (
      <Modal
        title={intl.formatMessage({ id: "clients.delete" })}
        content={intl.formatMessage({ id: "clients.message" })}
        actions={this.renderActions()}
      />
    );
  }
}
export default connect(null, { deleteClient })(injectIntl(DeleteClient));
