import React from "react";
import TimeControl from "./TimeControl";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getReports, clearReportsData } from "./redux/actions";
import { ColorContext } from "../../Root";

import { FormattedMessage } from "react-intl";

class Report extends React.Component {
  static contextType = ColorContext;

  componentDidMount() {
    const { getReports, match } = this.props;
    getReports(match.params.id);
  }

  componentWillUnmount() {
    this.props.clearReportsData();
  }

  renderBreadcrumb = () => {
    const { user } = this.props;
    return (
      <div className="ui big breadcrumb">
        <Link
          className="section"
          style={{ color: this.context.backgroundColor }}
          to="/dashboard"
        >
          <FormattedMessage id="common.users" />
        </Link>
        <i className="right chevron icon divider"></i>
        <h4 className="section">{user.displayName}</h4>
      </div>
    );
  };

  render() {
    const { reports, user } = this.props;
    return (
      <div>
        {this.renderBreadcrumb()}
        <div className="ui segment">
          <TimeControl reports={reports} user={user} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.users.length
      ? state.users.find((user) => ownProps.match.params.id === user.id)
      : state.user,
    reports: state.reports,
  };
};

export default connect(mapStateToProps, { getReports, clearReportsData })(
  Report
);
