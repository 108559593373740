import React from "react";
import Client from "./Client";
import ProjectsList from "../Projects/ProjectsList";
import { connect } from "react-redux";
import { selectClient } from "../redux/actions";
import { FormattedMessage } from "react-intl";
import "../../../assets/css/tables.css";

class ClientList extends React.Component {
  state = { clientClicked: false, id: "", name: "" };

  renderClients = () => {
    const { clients, user } = this.props;
    return clients.map((client) => {
      if (client.organizationId === user.organizationId) {
        return (
          <Client
            key={client.id}
            client={client}
            viewProjects={this.renderProjects}
          />
        );
      } else {
        return null;
      }
    });
  };

  renderProjects = ({ id, name }) => {
    const client = { id, name, clientClicked: true };
    this.props.selectClient(client);
  };

  renderHead = () => (
    <thead>
      <tr>
        <th className="wd10">
          <FormattedMessage id="common.name" />
        </th>
        <th className="wd20"> Email </th>
        <th className="wd30">
          <FormattedMessage id="clients.notes" />
        </th>
        <th className="wd10">
          <FormattedMessage id="clients.activeProjects" />
        </th>
        <th className="wd30"></th>
      </tr>
    </thead>
  );

  renderProjectsTable = (selectedClient) => (
    <div>
      <div className="ui basic large label">
        <FormattedMessage id="projects.for" /> {selectedClient.name}
      </div>
      <ProjectsList id={selectedClient.id} />
    </div>
  );

  render() {
    const { clients, selectedClient } = this.props;

    if (!clients) {
      return (
        <div className="ui basic placeholder segment">
          <div className="ui icon header">
            <i className="file outline icon" />
            <FormattedMessage id="clients.noClients" />
          </div>
        </div>
      );
    } else if (clients.length) {
      return (
        <div>
          <table className="ui selectable fixed table">
            {this.renderHead()}
            <tbody>{this.renderClients()}</tbody>
          </table>
          {selectedClient.name ? (
            this.renderProjectsTable(selectedClient)
          ) : (
            <div />
          )}
        </div>
      );
    } else {
      return <div className="ui basic loading placeholder segment" />;
    }
  }
}

const mapStateToProps = (state) => {
  let selectedClient = {};
  if (state.clients.length) {
    state.clients.forEach((client) => {
      if (
        client.id === state.selectedClient.id &&
        client.name === state.selectedClient.name
      ) {
        selectedClient = state.selectedClient;
      }
    });
  }

  return {
    clients: state.clients,
    user: state.user,
    selectedClient: selectedClient,
  };
};

export default connect(mapStateToProps, { selectClient })(ClientList);
