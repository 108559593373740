import React from "react";
import Modal from "../../Modal";
import { ColorContext } from "../../Root";
import { getStyle } from "../../assets/styles/styles";

import { Link } from "react-router-dom";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { getProjects } from "../../components/Projects/redux/actions";
import { addReport } from "./redux/actions";
import { validate } from "../../utils/validations";

class AddReport extends React.Component {
  state = { isLoading: false };
  static contextType = ColorContext;

  componentDidMount() {
    this.props.getProjects();
  }

  renderContent = () => {
    const { handleSubmit, intl, projects, match } = this.props;
    return (
      <form className="ui form" onSubmit={handleSubmit(this.onSubmit)}>
        <div className="field">
          <label>{intl.formatMessage({ id: "addReport.date" })}</label>
          <Field name="date" type="text" disabled={true} component="input" />
        </div>
        <div className="required field">
          <label>{intl.formatMessage({ id: "common.project" })}</label>
          <Field name="project" type="select" component="select">
            {this.renderOptions()}
          </Field>
        </div>
        <div className="two fields">
          <div className="field">
            <label>
              <FormattedMessage id="addReport.hours" />
            </label>
            <Field name="hours" component={this.renderField} />
          </div>
          <div className="field">
            <label>
              <FormattedMessage id="addReport.minutes" />
            </label>
            <Field name="minutes" component={this.renderField} />
          </div>
        </div>

        <div className="required field">
          <label>{intl.formatMessage({ id: "common.description" })}</label>
          <Field name="description" component="textarea" />
        </div>

        <Link
          to={`/dashboard/report/${match.params.id}`}
          className="ui right floated button"
        >
          <FormattedMessage id="common.cancel" />
        </Link>
        <button
          disabled={projects.length ? false : true}
          className={
            this.state.isLoading
              ? "ui right floated loading button"
              : `ui right floated button`
          }
          style={getStyle(this.context).button}
        >
          <FormattedMessage id="clients.add" />
        </button>
      </form>
    );
  };

  renderOptions = () => {
    const { projects, intl } = this.props;
    return (
      <React.Fragment>
        <option value="">
          {projects.length
            ? intl.formatMessage({ id: "addReport.select" })
            : intl.formatMessage({ id: "addReport.noProjects" })}
        </option>
        {projects.map((project) => (
          <option value={project.id} key={project.id}>
            {project.name}
          </option>
        ))}
      </React.Fragment>
    );
  };

  renderField = ({ input, meta: { touched, error } }) => (
    <div>
      <input {...input} type="number" />
      {touched && error && <span>{error}</span>}
    </div>
  );

  onSubmit = async ({ minutes, hours, description, date, project }) => {
    const { intl } = this.props;
    if (!description || !project) {
      !description
        ? window.alert(intl.formatMessage({ id: "errors.noDescription" }))
        : window.alert(intl.formatMessage({ id: "errors.noProject" }));
      throw new SubmissionError("Error");
    } else {
      const { user, projects, addReport } = this.props;
      const time = `${hours < 10 ? "0" + hours : hours}:${
        minutes < 10 ? "0" + minutes : minutes
      }`;
      const selectedProject = projects.find(
        (element) => element.id === project
      );
      const report = {
        date,
        description,
        projectId: selectedProject.id,
        projectName: selectedProject.name,
        time,
        usersId: user,
      };
      addReport(report);
      this.setState({ isLoading: true });
    }
  };

  render() {
    const { intl } = this.props;
    return (
      <Modal
        title={intl.formatMessage({ id: "addReport.title" })}
        content={this.renderContent()}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const user = ownProps.match.params.id;
  const projectsForThisUser = [];
  state.projects.forEach((project) => {
    if (project.usersId.find((userId) => userId === user)) {
      projectsForThisUser.push(project);
    }
  });
  return {
    user: user,
    projects: projectsForThisUser,
    initialValues: {
      date: ownProps.match.params.date,
      hours: 0,
      minutes: 0,
    },
  };
};

export default connect(mapStateToProps, { getProjects, addReport })(
  reduxForm({ form: "addReportForm", validate })(injectIntl(AddReport))
);
