import {
  WATCH_GET_CLIENTS,
  SET_CLIENTS,
  ADD_CLIENT,
  EDIT_CLIENT,
  DELETE_CLIENT,
  SELECTED_CLIENT,
  WATCH_GET_PROJECTS,
  SET_PROJECTS,
  ADD_PROJECT,
  EDIT_PROJECT,
  DELETE_PROJECT,
  EDIT_USERSPROJECT,
} from "./types";

export const getClients = (searchTerm) => ({
  type: WATCH_GET_CLIENTS,
  searchTerm,
});

export const setClients = (query, searchTerm) => ({
  type: SET_CLIENTS,
  query,
  searchTerm,
});

export const addClients = (client) => ({
  type: ADD_CLIENT,
  client,
});

export const selectClient = (client) => ({
  type: SELECTED_CLIENT,
  client,
});

export const editClient = (client) => ({
  type: EDIT_CLIENT,
  client,
});

export const deleteClient = (client) => ({
  type: DELETE_CLIENT,
  client,
});

export const getProjects = () => ({
  type: WATCH_GET_PROJECTS,
});

export const setProjects = (query) => ({
  type: SET_PROJECTS,
  query,
});

export const addProject = (client, project) => ({
  type: ADD_PROJECT,
  project,
  client,
});

export const editProject = (project) => ({
  type: EDIT_PROJECT,
  project,
});

export const editUsersProject = (users, projectId) => ({
  type: EDIT_USERSPROJECT,
  users,
  projectId,
});

export const deleteProject = (project) => ({
  type: DELETE_PROJECT,
  project,
});
