import React from "react";
import Modal from "../../../Modal";
import { Link } from "react-router-dom";
import { deleteUser } from "./redux/actions";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";

const renderActions = (props) => {
  return (
    <React.Fragment>
      <button
        className="ui red button"
        onClick={() => {
          props.deleteUser(props.match.params.id);
        }}
      >
        <FormattedMessage id="common.delete" />
      </button>
      <Link to="/dashboard" className="ui button">
        <FormattedMessage id="common.cancel" />
      </Link>
    </React.Fragment>
  );
};

const DeleteUser = (props) => {
  return (
    <Modal
      title={props.intl.formatMessage({ id: "deleteUser.title" })}
      content={props.intl.formatMessage({ id: "dashboard.delete" })}
      actions={renderActions(props)}
    />
  );
};

export default connect(null, { deleteUser })(injectIntl(DeleteUser));
